import React from 'react';

const InfoPopup = ({ color = '', text = '', boldText = '', icon: Icon }) => {
  return (
    <div className={`p-4 mb-4 text-sm text-${color}-800 bg-${color}-100 rounded-lg`} role="alert">
      <div className="flex items-start">
        {Icon && <Icon className="inline-block w-5 h-5 mr-2" />} {/* Render the passed icon */}
        <div>
          {boldText && <strong className="mr-1">{boldText}</strong>} {/* Render bold text if provided */}
          <span className="whitespace-normal">{text}</span> {/* Allow normal white space to support multiline */}
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
