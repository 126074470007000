import React from 'react';
import backgroundImage from '../images/hero/500.svg';
import Footer from '../components/Footer';
import { Home } from 'lucide-react';
import Tooltip from '../components/Tooltip'; // Import the Tooltip component if it's in a separate file

const Error = () => {
    const handleGoHome = () => {
        // Redirect to the desired URL (https://www.milkiway.ai/)
        window.location.href = 'https://www.milkiway.ai/';
    };
    return (
        <div>
            <div className="flex-center">
                <img
                    src={backgroundImage}
                    alt="Error"
                    className="w-full h-full object-cover fixed inset-0 z-0"
                />
                <div className="fixed top-40 left-20 ml-4">
                    <div className="text-5xl md:text-4xl sm:text-3xl font-extrabold pb-5 bg-gradient-to-r text-white bg-clip-text text-transparent w-100">
                        ERROR CODE: 500 Internal Server Error
                    </div>
                    <div className="flex items-center">
                        <Tooltip text="Go Home">
                            <button onClick={handleGoHome} type="button" className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2">
                                <Home />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Error;
