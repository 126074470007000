import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ToolbarButton from '../ToolbarButton';
import { Pencil, Copy, Check, Printer, Link2, AlertTriangle } from 'lucide-react';
import useTemplate from '../../hooks/useTemplate';
import InfoPopup from '../alerts/InfoPopup';
import LoadingPopup from '../LoadingPopup';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL

interface VisitData {
  id: number;
  createdAt: string;
  uuid: string;
  visitDate: string;
  healthScreeningPackageUuid?: string;
  healthScreeningPackageName?: string;
}

interface CardVisitListProps {
  patientId: number;
}

const CardVisitList: React.FC<CardVisitListProps> = ({ patientId }) => {
  const [visitData, setVisitData] = useState<VisitData[]>([]);
  // Initialize a state to track copy success for each item
  const [copyURLSuccesses, setCopyURLSuccesses] = useState<{ [uuid: string]: boolean }>({});
  const [copySuccesses, setCopySuccesses] = useState<{ [uuid: string]: boolean }>({});
  const [initialCardCount, setInitialCardCount] = useState(3); // Number of cards to display initially
  const { uuid: urlUUID } = useParams<{ uuid: string }>();
  const { templateData } = useTemplate();
  const [healthScreeningTemplate, setHealthScreeningTemplate] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchVisitData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/visit/all/${patientId}`,
          { withCredentials: true }
        );
  
        const visitData = response.data;
  
        // Convert createdAt timestamps and set registrationDate
        const updatedVisitData = visitData.map((item) => ({
          ...item,
          registrationDate: convertUTCToSGTimestamp(item.createdAt),
        }));
  
        // Sort data in reverse order based on createdAt timestamp
        const sortedVisitData = updatedVisitData.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
  
        const enhancedVisitData = sortedVisitData.map((visit) => {
          if (visit.healthScreeningPackageUuid) {
           // console.log("Matching Package UUID:", visit.healthScreeningPackageUuid);
        
            // Access the HealthScreeningPackage array from templateData
            const healthScreeningPackages = templateData.HealthScreeningPackage || []; // Default to empty array if not found
        
            // Find the health screening package using the UUID
            const matchedPackage = healthScreeningPackages.find(
              (hsPackage) => hsPackage.healthScreeningPackageUuid === visit.healthScreeningPackageUuid
            );
        
            if (matchedPackage) {
             // console.log("Matched Package:", matchedPackage);
              return {
                ...visit,
                healthScreeningPackageName: matchedPackage.healthScreeningPackageName, // Assign the package name
              };
            } else {
            //  // console.log("No matching package found for:", visit.healthScreeningPackageUuid);
            }
          }
        
          // Return visit data unchanged if no healthScreeningPackageUuid or no match found
          return visit;
        });
        // Set the enhanced visit data
        setVisitData(enhancedVisitData);
      } catch (error) {
        console.error('Error fetching visit data:', error);
      }
    };
  
    fetchVisitData();
  }, [patientId, templateData]);
  
  
  const handleCopyReportURL = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    // Construct the URL to be copied
    const reportURL = `${REACT_APP_FRONTEND_BASE_URL}/passwordcheck/${uuid}`;
    // Copy the URL to the clipboard
    const el = document.createElement('textarea');
    el.value = reportURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  
    // Set the copy success for the specific UUID to true
    setCopyURLSuccesses((prevCopyURLSuccesses) => ({
      ...prevCopyURLSuccesses,
      [uuid]: true,
    }));
  
    // Reset copySuccess after a short delay
    setTimeout(() => {
    setCopyURLSuccesses((prevCopyURLSuccesses) => ({
      ...prevCopyURLSuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  const handleOpenPrintView = async (event: React.MouseEvent, uuid: string, clinicId: number) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    setIsLoading(true); // Set loading state to true
  
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/pdf/report`,
        {
          clinicId,  // Pass clinicId along with uuid
          uuid,
        },
        {
          withCredentials: true, // This ensures cookies are sent with the request
          responseType: 'arraybuffer', // Important: Set responseType to 'arraybuffer' to handle binary data correctly
        }
      );
      
      console.log('response:', response); // Log the entire response object for debugging
  
      // Handle successful response (if response contains data)
      if (response.status === 200 && response.data) {
        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      
        // Create a URL for the Blob
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
      
        // Create a link to download the file
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open in a new tab
        link.download = `report_${uuid}.pdf`; // Optional: set the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element
      
        // Optionally, revoke the object URL after use (to free up memory)
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error('Invalid response or empty PDF data');
      }
  
      setIsLoading(false); // Set loading state to false after processing
  
    } catch (error) {
      setIsLoading(false); // Ensure loading state is reset if an error occurs
      setError(true); // Set error state to true
  
      // Detailed error logging for different types of errors
      if (error.response) {
        // The server responded with a status code that falls out of the range of 2xx
        console.error('Error response data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
  
      // Optionally, handle specific error codes (e.g., 500 for server error, etc.)
      if (error.response && error.response.status === 500) {
        console.error('Server error occurred while generating PDF.');
      } else if (error.response && error.response.status === 400) {
        console.error('Bad request, check the API payload.');
      }
  
      // Reset error state after a timeout to hide the error message
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };  

  // Function to handle copying the UUID of the currently clicked item
  const handleCopyData = (uuid: string) => {
    // Copy the UUID to the clipboard
    const el = document.createElement('textarea');
    el.value = uuid;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Set the copy success for the specific UUID to true
    setCopySuccesses((prevCopySuccesses) => ({
      ...prevCopySuccesses,
      [uuid]: true,
    }));

    // Reset copySuccess after a short delay
    setTimeout(() => {
      setCopySuccesses((prevCopySuccesses) => ({
        ...prevCopySuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  const loadMore = () => {
    setInitialCardCount(initialCardCount + 5);
  };

  const navigate = useNavigate(); // Initialize the useHistory hook

  const handleEdit = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    event.stopPropagation(); // Stop event propagation
    navigate(`/patient/screening/${uuid}`);
  };

  return (
    // <!-- Features -->
    <div className="rounded-lg">
      <LoadingPopup isLoading={isLoading} />
      {/* <!-- Grid --> */}
      <h3 className="text-xl font-semibold text-black dark:text-white px-2 py-3">
        Visit History
      </h3>
      {error && (
      <InfoPopup color="red" boldText="Error!" text="Error encountered in generating a report." icon={AlertTriangle} />
    )}
      {/* <!-- Tab Navs --> */}
      <nav className="grid" aria-label="Tabs" role="tablist">
        {visitData.slice(0, initialCardCount).map((item) => (
          <div
          className={`my-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:hover-bg-gray-700 ${
            item.uuid === urlUUID ? 'bg-gray-100' : ''
          }`}
          key={item.id}
        >          <a
        href={`${REACT_APP_FRONTEND_BASE_URL}/patient/${item.uuid}`}
        className="block"
      >
      
              <div className="cursor-pointer py-4">
                <div className="px-6 flex items-center">
                  <h5 className="text-sm font-bold text-left text-gray-900 dark:text-white">
                    Visit Date:
                  </h5>
                  <p className="text-gray-700 text-xs pl-2 dark:text-white">
                    {convertUTCToSGTimestamp(item.visitDate)}
                  </p>
                  <div className="pl-2">
                     <span className="inline-block bg-primaryColor-50 rounded-lg px-3 py-1 text-xs font-medium text-primaryColor-500 mr-2 mb-2">
                      {item.healthScreeningPackageName}
                    </span>
                  </div>
                </div>
                <div className="px-6 flex justify-between">
                  <div className="flex items-center">
                    <h5 className="inline-block text-sm font-bold text-left text-gray-900 dark:text-white">
                      Visit ID:
                    </h5>
                    <p className="inline-block text-gray-700 text-xs pl-2 dark:text-white">
                      {item.uuid}
                    </p>
                  </div>
                  <div className="flex">

                  <ToolbarButton
                      text="Copy Report URL"
                      icon={
                        !copyURLSuccesses[item.uuid] ? (
                          <Link2 className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyReportURL(e, item.uuid);
                      }}
                    />

                  <ToolbarButton text="Print Report" icon={<Printer className="w-4 h-4" />} onClick={(e) => handleOpenPrintView(e, item.uuid)} />
                    <ToolbarButton
                      text="Copy Visit ID"
                      icon={
                        !copySuccesses[item.uuid] ? (
                          <Copy className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyData(item.uuid);
                      }}
                    />
                    <ToolbarButton text="Edit Records" icon={<Pencil className="w-4 h-4" />} onClick={(e) => handleEdit(e, item.uuid)} />
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </nav>
      {/* <!-- End Tab Navs --> */}
      {initialCardCount < visitData.length && (
        <div className="flex justify-center my-2">
          <button
            onClick={loadMore}
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Load more
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CardVisitList;
