import React, { useState, useEffect } from 'react';
import Vision from '../testFormRenders/Vision';
import Tonometry from '../testFormRenders/Tonometry';
import Audiogram from '../testFormRenders/Audiogram';
import Colonoscopy from '../testFormRenders/Colonoscopy';

interface FormProps {
    formData: FormDataItem[] | null;
}

interface FormDataItem {
    uuid: string;
    formContent: any; // Allow formContent to be any type (object or string)
    formType: string;
}

const TestProcedures: React.FC<FormProps> = ({ formData }) => {
    const [vision, setVision] = useState<FormDataItem[]>([]);
    const [tonometry, setTonometry] = useState<FormDataItem[]>([]);
    const [audiogram, setAudiogram] = useState<FormDataItem[]>([]);
    const [colonoscopy, setColonoscopy] = useState<FormDataItem[]>([]);
    const [activeTab, setActiveTab] = useState<string>('');

    useEffect(() => {
        if (formData) {
            const filteredVision = formData.filter(item => item.formType === 'Vision');
            const filteredTonometry = formData.filter(item => item.formType === 'Tonometry');
            const filteredAudiogram = formData.filter(item => item.formType === 'Audiogram');
            const filteredColonoscopy = formData.filter(item => item.formType === 'Colonoscopy');

            setVision(filteredVision);
            setTonometry(filteredTonometry);
            setAudiogram(filteredAudiogram);
            setColonoscopy(filteredColonoscopy);

            // Set the initial active tab based on available data
            if (filteredVision.length > 0) {
                setActiveTab('vision');
            } else if (filteredTonometry.length > 0) {
                setActiveTab('tonometry');
            } else if (filteredAudiogram.length > 0) {
                setActiveTab('audiogram');
            } else if (filteredColonoscopy.length > 0) {
                setActiveTab('colonoscopy');
            }
        }
    }, [formData]);

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <div className="py-4">
            <div className="flex text-sm">
                {vision.length > 0 && (
                    <button
                        className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'vision'
                            ? 'text-white bg-primaryColor-500'
                            : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        onClick={() => handleTabClick('vision')}
                    >
                        Vision
                    </button>
                )}
                {tonometry.length > 0 && (
                    <button
                        className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'tonometry'
                            ? 'text-white bg-primaryColor-500'
                            : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        onClick={() => handleTabClick('tonometry')}
                    >
                        Tonometry
                    </button>
                )}
                {audiogram.length > 0 && (
                    <button
                        className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'audiogram'
                            ? 'text-white bg-primaryColor-500'
                            : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        onClick={() => handleTabClick('audiogram')}
                    >
                        Audiogram
                    </button>
                )}
                {colonoscopy.length > 0 && (
                    <button
                        className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'colonoscopy'
                            ? 'text-white bg-primaryColor-500'
                            : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        onClick={() => handleTabClick('colonoscopy')}
                    >
                        Colonoscopy
                    </button>
                )}
            </div>

            <div className="rounded-b-lg bg-gray-100 dark:bg-gray-800 p-4">
                {activeTab === 'vision' && vision.length > 0 && (
                    <div>
                        {/* Loop through vision data */}
                        {vision.map((item) => (
                            <Vision key={item.uuid} visionData={item.formContent} />
                        ))}
                    </div>
                )}

                {activeTab === 'tonometry' && tonometry.length > 0 && (
                    <div>
                        {/* Loop through tonometry data */}
                        {tonometry.map((item) => (
                            <Tonometry key={item.uuid} tonometryData={item.formContent} />
                        ))}
                    </div>
                )}

                {activeTab === 'audiogram' && audiogram.length > 0 && (
                    <div>
                        {/* Loop through audiogram data */}
                        {audiogram.map((item) => (
                            <Audiogram key={item.uuid} audiogramData={item.formContent} />
                        ))}
                    </div>
                )}

                {activeTab === 'colonoscopy' && colonoscopy.length > 0 && (
                    <div>
                        {/* Loop through colonoscopy data */}
                        {colonoscopy.map((item) => (
                            <Colonoscopy key={item.uuid} colonoscopyData={item.formContent} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TestProcedures;
