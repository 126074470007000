import React, { useEffect, useState } from 'react';
import { List, Plus, X } from 'lucide-react';
import axios from 'axios';
import ToolbarButton from '../ToolbarButton';
import sanitizeInput from '../../function/sanitizeInput';

const buttonStyle =
  'text-black w-full bg-gray-200 hover:text-white hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const requiredAsteriskStyle = 'text-red-500 mx-1'; // Define the style for the red asterisk
const textStyle = 'text-sm font-medium text-gray-900 dark:text-white';
const inputSelectorStyle =
  'bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-form-input dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300';

interface ClinicListData {
  id: string;
  listName: string;
}

interface PatientListData {
  id: string;
  listName: string;
}

interface CardAddToListProps {
  patientId: number;
}

const CardAddToList: React.FC<CardAddToListProps> = ({ patientId }) => {
  const [clinicLists, setClinicLists] = useState<ClinicListData[]>([]);
  const [patientLists, setPatientLists] = useState<ClinicListData[]>([]);

  const [selectedListId, setSelectedListId] = useState<string>(''); // State to track selected listId
  const [error, setError] = useState<string | null>(null);
  const fetchAllList = async () => {
    try {
      const response = await axios.get<PatientListData[]>(
        `${REACT_APP_API_BASE_URL}/list/all`,
        { withCredentials: true },
      );
      // console.log('Clinic list response:', response.data);
      setClinicLists(response.data);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error fetching lists:', error);
      setError('Failed to fetch lists. Please try again.'); // Set error message
    }
  };

  const fetchListData = async () => {
    try {
      const response = await axios.get<PatientListData[]>(
        `${REACT_APP_API_BASE_URL}/list/patient/${patientId}`,
        { withCredentials: true },
      );
      // console.log('List response:', response.data);
      setPatientLists(response.data);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error fetching lists:', error);
      setError('Failed to fetch lists. Please try again.'); // Set error message
    }
  };
  useEffect(() => {
    fetchAllList();
    fetchListData();
  }, [patientId]);

  const handleAddtoList = async () => {
    try {
      if (selectedListId) {
        // console.log('selectedListId', selectedListId);
        await axios.post(
          `${REACT_APP_API_BASE_URL}/list/patient/add`,
          { patientId, listId: selectedListId },
          { withCredentials: true },
        );
        // console.log('Patient added to list successfully');
        await fetchListData();
      } else {
        console.error('No list selected.');
      }
    } catch (error) {
      console.error('Error adding patient to list:', error);
      // Handle error (e.g., show error message to user)
    }
  };
  const handleRemovefromList = async (patientId: number, listId: string) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/list/patient/remove`,
        { patientId, listId },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            // Add any other necessary headers here
          },
        },
      );
      // console.log('Patient removed from list successfully', response.data);
      // Fetch updated patient lists
      const updatedResponse = await axios.get<PatientListData[]>(
        `${REACT_APP_API_BASE_URL}/list/${patientId}`,
        { withCredentials: true },
      );
      setPatientLists(updatedResponse.data);
    } catch (error) {
      console.error('Error removing patient from list:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleListSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedListId(event.target.value);
  };

  return (
    <div className="rounded-lg px-2 py-3">
      <h3 className="text-xl font-semibold text-black dark:text-white pb-4">
        Working List
      </h3>

      <div className="mb-4">
        <label className={textStyle}>
          Select List to Add Patient
          <span className={requiredAsteriskStyle}>*</span>
        </label>
        <select
          name="selectedListId"
          value={selectedListId}
          onChange={handleListSelectChange}
          className={inputSelectorStyle}
        >
          <option value="">Select a list</option>
          {clinicLists.map((item) => (
            <option key={item.id} value={item.id}>
              {item.listName}
            </option>
          ))}
        </select>
      </div>

      <button
        className={`flex items-center justify-start ${buttonStyle}`}
        onClick={handleAddtoList}
      >
        <Plus className="inline-block w-4 h-4 mr-4" /> Add Patient to List
      </button>

      <div className="mt-4">
        {patientLists.length > 0 ? (
          <table className="min-w-full rounded-lg border-collapse border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-2 px-4 text-left text-sm text-black">
                  List Name
                </th>
                <th className="py-2 px-4 text-left text-sm text-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {patientLists.map((item) => (
                <tr key={item.id} className="border-t border-gray-200">
                  <td className="py-2 px-4 text-sm text-black">
                    <List className="inline-block w-4 h-4 mr-2" />
                    {item.listName}
                  </td>
                  <td className="py-2 px-4">
                    <button>
                      <ToolbarButton
                        text="Remove patient from list"
                        icon={<X className="w-4 h-4" />}
                        onClick={() => handleRemovefromList(patientId, item.id)}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No lists found for this patient.</p>
        )}
      </div>
    </div>
  );
};

export default CardAddToList;
