import React, { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";

interface BloodPressureProps {
  measureData?: {
    systolicBp?: number;
    diastolicBp?: number;
    bpCategory?: string;
    heartrate?: number;
    createdAt?: string;
    PatientId?: number;
  };
}

const commonLabelStyles =
  "flex flex-col mt-1 text-left text-sm text-gray-600 dark:text-gray-400";
const commonpClassStyles = "text-sm text-gray-800";

const BloodPressure: React.FC<BloodPressureProps> = ({ measureData }) => {
  const [systolicBp, setSystolicBp] = useState<number | null>(null);
  const [diastolicBp, setDiastolicBp] = useState<number | null>(null);
  const [bpCategory, setBpCategory] = useState<string>('');
  const [heartrate, setHeartrate] = useState<number | null>(null);

  useEffect(() => {
    if (measureData?.systolicBp !== undefined) {
      setSystolicBp(measureData.systolicBp);
    }

    if (measureData?.diastolicBp !== undefined) {
      setDiastolicBp(measureData.diastolicBp);
    }

    if (measureData?.heartrate !== undefined) {
      setHeartrate(measureData.heartrate);
    }

    if (measureData?.bpCategory !== undefined) {
      setBpCategory(measureData.bpCategory);
    }
  }, [measureData]);

  let bpContent;

  if (bpCategory && bpCategory !== "Normal") {
    bpContent = (
      <div className="px-10 pt-6">
        <p className={commonpClassStyles}>
          Untreated high blood pressure increases the risk of heart attack,
          stroke, and other serious health problems. Healthy lifestyle habits
          can help prevent and treat high blood pressure. Some people may need
          medication.
        </p>
      </div>
    );
  }

  let referenceChart;

  if (bpCategory !== "Normal") {
    referenceChart = (
      <div className="px-10">
        <div className="relative pt-8">
          {bpCategory === "Normal" && (
            <BsArrowDown className="h-30 text-black absolute left-1/4 top-[-50px]" />
          )}
          {bpCategory === "High-normal" && (
            <>
              <BsArrowDown className="h-30 text-black absolute left-1/4 top-[-50px]" />
              <div className="w-1/4" />
            </>
          )}
          {bpCategory === "Grade 1 hypertension" && (
            <>
              <BsArrowDown className="h-30 text-black absolute left-1/2 top-[-50px]" />
              <div className="w-1/2" />
            </>
          )}
          {bpCategory === "Grade 2 hypertension" && (
            <>
              <BsArrowDown className="h-30 text-black absolute left-3/4 top-[-50px]" />
              <div className="w-3/4" />
            </>
          )}
        </div>
        <div className="grid grid-cols-1">
          <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
            <div
              className="flex flex-col justify-center overflow-hidden bg-green-400"
              style={{ width: "25%" }}
            ></div>
            <div
              className="flex flex-col justify-center overflow-hidden bg-amber-400"
              style={{ width: "25%" }}
            ></div>
            <div
              className="flex flex-col justify-center overflow-hidden bg-pink-300"
              style={{ width: "25%" }}
            ></div>
            <div
              className="flex flex-col justify-center overflow-hidden bg-red-400"
              style={{ width: "25%" }}
            ></div>
          </div>
        </div>
        <div className="grid grid-cols-4">
          <div className={commonLabelStyles}>Normal</div>
          <div className={commonLabelStyles}>High-normal </div>
          <div className={commonLabelStyles}>Grade 1</div>
          <div className={commonLabelStyles}>Grade 2</div>
        </div>
      </div>
    );
  }

  // Check if measureData is empty
  if (!measureData || Object.keys(measureData).length === 0) {
    return (
      <div className="text-red-600">
        Missing data. Please contact your clinic.
      </div>
    );
  }

  return (
    <div id="bp" className="flex">
      <div className="w-full sm:w-1/2">
        <p className={commonpClassStyles}>
          Your blood pressure category is <strong>{bpCategory}</strong>.
        </p>
        <div className="pt-6 min-w-full overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-6 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Systolic BP (mmHg)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-6 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Diastolic BP (mmHg)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-6 sm:py-3  w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Heart Rate (bpm)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-6 sm:py-3 w-2/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    BP Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`px-3 py-2 sm:px-6 sm:py-3 w-2/6 whitespace-nowrap text-sm  dark:text-gray-200
                ${measureData.systolicBp !== undefined && measureData.systolicBp < 130 ? "text-black" : ""}
                ${measureData.systolicBp !== undefined && measureData.systolicBp >= 130 && measureData.systolicBp <= 139 ? "text-amber-400" : ""}
                ${measureData.systolicBp !== undefined && measureData.systolicBp >= 140 && measureData.systolicBp <= 159 ? "text-pink-500" : ""}
                ${measureData.systolicBp !== undefined && measureData.systolicBp >= 160 && measureData.systolicBp <= 179 ? "text-red-700" : ""}
              `}
                  >
                    {" "}
                    {measureData.systolicBp}
                  </td>
                  <td
                    className={`px-3 py-2 sm:px-6 sm:py-3 w-1/6 whitespace-nowrap text-sm dark:text-gray-200
                ${measureData.diastolicBp !== undefined && measureData.diastolicBp < 85 ? "text-black" : ""}
                ${measureData.diastolicBp !== undefined && measureData.diastolicBp >= 85 && measureData.diastolicBp <= 89 ? "text-amber-400" : ""}
                ${measureData.diastolicBp !== undefined && measureData.diastolicBp >= 90 && measureData.diastolicBp <= 99 ? "text-pink-500" : ""}
                ${measureData.diastolicBp !== undefined && measureData.diastolicBp >= 100 && measureData.diastolicBp <= 109 ? "text-red-700" : ""}
              `}
                  >
                    {" "}
                    {measureData.diastolicBp}
                  </td>
                  <td
                    className={`px-3 py-2 sm:px-6 sm:py-3w-1/6 whitespace-nowrap text-sm  dark:text-gray-200

              ${measureData.heartrate !== undefined && measureData.heartrate >= 100 ? "text-red-700" : "text-black"}`}
                  >
                    {measureData.heartrate}
                  </td>
                  <td className="px-3 py-2 sm:px-6 sm:py-3 w-2/6 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                    {bpCategory === "Normal" ? (
                      <span className="text-xs rounded py-1 px-1 bg-green-100 text-green-700">
                        {bpCategory}
                      </span>
                    ) : bpCategory === "High-normal" ? (
                      <span className="text-xs rounded py-1 px-1 bg-amber-100 text-amber-600">
                        {bpCategory}
                      </span>
                    ) : bpCategory === "Grade 1 hypertension" ? (
                      <span className="text-xs rounded py-1 px-1 bg-pink-100 text-pink-700">
                        {bpCategory}
                      </span>
                    ) : (
                      <span className="text-xs rounded py-1 px-1 bg-red-100 text-red-800">
                        {bpCategory}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2 p-4">
        {bpContent}
        {referenceChart}
      </div>
    </div>
  );
};

export default BloodPressure;
