import { useState } from 'react';
import StepOneForm from '../../components/onboarding/StepOneForm';
import StepTwoForm from '../../components/onboarding/StepTwoForm';
import StepThreeForm from '../../components/onboarding/StepThreeForm';
import Footer from '../../components/Footer';

const CreateClinic = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [clinicId, setClinicId] = useState('');
  const [clinicKey, setClinicKey] = useState(''); // Define clinicKey in the parent component
  const [invitationCode, setInvitationCode] = useState('');

  const handleNextStep = (newClinicId, newClinicKey, newInvitationCode) => {
    setClinicId(newClinicId);
    setClinicKey(newClinicKey);
    setInvitationCode(newInvitationCode); // Set the invitationCode when moving to the next step
    setCurrentStep(currentStep + 1);
  };
  

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="mt-10 w-full bg-white p-8 rounded shadow">
        {currentStep === 1 && (
          <StepOneForm onNext={handleNextStep} />
        )}
{currentStep === 2 && (
  <StepTwoForm
    clinicId={clinicId}
    invitationCode={invitationCode} // Pass invitationCode to StepTwoForm
    onNext={handleNextStep}
    onPrev={handlePrevStep}
  />
)}
        {currentStep === 3 && (
          <StepThreeForm clinicKey={clinicKey} onPrev={handlePrevStep} />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default CreateClinic;
