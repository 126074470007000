import React,{ useState } from "react";
import { ListFilter } from "lucide-react";

interface SearchBarTablePatientListProps {
  onSearch: (searchQuery: string) => void;
}

const SearchBarTablePatientList: React.FC<SearchBarTablePatientListProps> = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
      <div className="flex flex-wrap justify-between items-center"> 
        <div className="block relative">
        <ListFilter className="absolute inset-y-4 left-4 flex items-center pl+2 pointer-events-none width-1" />
          <input
            type="search"
            id="default-search"
            className="block w-80 p-3 pl-14 text-sm text-gray-900 border border-gray-3 rounded-lg bg-gray-50 focus:ring-primaryColor-500 focus:border-primaryColor-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter name or NRIC"
            value={searchQuery}
            onChange={handleSearch}
            required
          />
        </div>
      </div>
    )};

export default SearchBarTablePatientList;
