import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cover from '../../images/cover/cover-geometric.svg';
import SuccessAlert from '../../components/alerts/SuccessAlert';
import ErrorAlert from '../../components/alerts/ErrorAlert';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const titleStyle = 'text-xl text-gray-900';
const textStyleStrong = 'text-sm font-medium text-gray-900 dark:text-white';
const textStyle = 'text-sm text-gray-900 dark:text-white';
const requiredAsteriskStyle = 'text-red-500 mx-1';
const inputCheckboxStyle =
  'mr-4 inline-block w-4 bg-white border border-gray-300 text-gray-900 text-base rounded focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500';

interface Option {
  value: string;
  label: string;
}

interface Field {
  name: string;
  label: string;
  type: string;
  required?: boolean;
  options?: Option[];
}

interface Section {
  title: string;
  fields: Field[];
}

interface FormTemplate {
  sections: Section[];
}

interface DevAssessmtTemplate {
  sections: Section[];
}

const DevelopmentalAssessment: React.FC = () => {
  const [templateType, setTemplateType] = useState<string | null>(null);
  const [formTemplate, setFormTemplate] = useState<FormTemplate | null>(null);
  const [devAssessmtTemplate, setDevAssessmtTemplate] = useState<DevAssessmtTemplate | null>(null);
  const [errorBoxHighlight, setErrorBoxHighlight] = useState<Record<string, boolean>>({});
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [formErrorMessages, setFormErrorMessages] = useState<Record<string, string>>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchTemplateData = async () => {
    try {
      const url = `${REACT_APP_API_BASE_URL}/patient/template/devassessment/QChild`;
      const response = await axios.get<FormTemplate>(url);
  
      if (response.data) {
        setFormTemplate(response.data.templateContent);
        // console.log(response.data.templateContent);
      } else {
        console.warn('Questionnaire template not found.');
      }
    } catch (error) {
      console.error('Error fetching form template:', error);
    }
  };

  const extractClinicId = () => {
    const currentPath = window.location.pathname;
    const [, , clinicId] = currentPath.split('/');
    return parseInt(clinicId, 10);
  };

  useEffect(() => {
    const clinicId = extractClinicId();
    if (!isNaN(clinicId)) {
      fetchTemplateData();
    } else {
      console.error('Invalid clinic ID');
    }
  }, []);

  const inputSelectorStyle = (fieldName: string) => {
    const borderColor = errorBoxHighlight[fieldName] ? 'border-red-500' : 'border-gray-300';

    return `
      bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5
      dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
      ${borderColor}
    `;
  };

  const handleTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const templateType = event.target.value;
    setTemplateType(templateType);
    fetchFormTemplate(templateType);
  };

  const fetchFormTemplate = async (templateType: string) => {
    try {
      const response = await fetch(`${REACT_APP_API_BASE_URL}/patient/template/devassessment/${templateType}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // console.log('Fetched developmental assessment template:', data);
      setDevAssessmtTemplate(data.templateContent); // Ensure this matches your expected data structure
    } catch (error) {
      console.error('Error fetching developmental assessment template:', error);
    }
  };

  const renderFields = (fields: Field[]) => {
    if (!fields) return null;
    return fields.map((field, idx) => (
      <div key={idx} className="w-full md:w-1/2 px-3 mb-4">
        <label htmlFor={field.name} className={textStyleStrong}>
          {field.label}
          {field.required && <span className={requiredAsteriskStyle}>*</span>}
        </label>

        {field.type === 'select' ? (
          <select
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={inputSelectorStyle(field.name)}
          >
            <option value="">Select an option</option>
            {field.options?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={field.type}
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={inputSelectorStyle(field.name)}
          />
        )}
        {formErrorMessages[field.name] && (
          <p className="text-red-500 text-xs mt-1">
            {formErrorMessages[field.name]}
          </p>
        )}
      </div>
    ));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateAge = (birthDate: string) => {
    const today = new Date();
    const birth = new Date(birthDate);
  
    // Calculate the difference in milliseconds
    const ageInMilliseconds = today.getTime() - birth.getTime();
    
    // Convert milliseconds to days
    const ageInDays = Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24));
    
    // Convert days to weeks and months
    const ageInMonths = Math.floor(ageInDays / 30.44); // Average number of days in a month
    const ageInYears = Math.floor(ageInDays / 365.25); // Average number of days in a year
   if (ageInMonths < 24) {
      return `${ageInMonths} months`;
    } else {
      return `${ageInYears} years`;
    }
  };
  

  const renderCheckbox = (field: Field) => (
    <div key={field.name} className="w-full px-3 mb-4">
      <div className="flex items-center">
        <input
          type="checkbox"
          name={field.name}
          id={field.name}
          checked={formData[field.name] || false}
          onChange={handleCheckboxChange}
          className={inputCheckboxStyle}
        />
        <label htmlFor={field.name} className={textStyle}>
          {field.label}
          {field.required && <span className={requiredAsteriskStyle}>*</span>}
        </label>
      </div>
      <div>
      {formErrorMessages[field.name] && (
        <p className="text-red-500 text-xs mt-1">
          {formErrorMessages[field.name]}
        </p>
      )}
      {field.description && (
        <p className="text-primaryColor-800 text-xs mt-1 ml-10">
          {field.description}
        </p>
      )}
      </div>
    </div>
  );


  const renderDropdownDevAssmt = (fields: Field[]) => {
    if (!fields) return null;
    return fields.map((field, idx) => (
      <div key={idx} className="w-full md:w-full px-3 mb-4">
        <label htmlFor={field.name} className={textStyleStrong}>
          {field.label}
          {field.required && <span className={requiredAsteriskStyle}>*</span>}
        </label>

        {field.type === 'select' ? (
          <select
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={inputSelectorStyle(field.name)}
          >
            <option value="">Select an option</option>
            {field.options?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={field.type}
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={inputSelectorStyle(field.name)}
          />
        )}
        {formErrorMessages[field.name] && (
          <p className="text-red-500 text-xs mt-1">
            {formErrorMessages[field.name]}
          </p>
        )}
      </div>
    ));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const renderAgeSection = () => {
    const birthDate = formData['birthDate'] as string;
    if (birthDate) {
      const age = calculateAge(birthDate);
      return (
        <div className="mb-4">
          <h3 className="text-3xl font-semibold text-gray-700">Your Child's Age</h3>
          <p className="text-2xl text-primaryColor-400 font-medium">{age}</p>
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Form validation
    let isValid = true;
    const newErrorMessages: Record<string, string> = {};
    const newErrorBoxHighlight: Record<string, boolean> = {};
  
    formTemplate?.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.required && !formData[field.name]) {
          isValid = false;
          newErrorMessages[field.name] = `${field.label} is required.`;
          newErrorBoxHighlight[field.name] = true;
        }
      });
    });
  
    setFormErrorMessages(newErrorMessages);
    setErrorBoxHighlight(newErrorBoxHighlight);
  
    if (!isValid) {
      // console.log("Form validation failed.");
      return;
    }
  
    try {
      const clinicId = extractClinicId();
  
      // Construct the dataToSend object
      const dataToSend = {
        clinicId: clinicId,
        name: formData.name,
        nric: formData.nric?.toUpperCase(), // Convert NRIC to uppercase if it exists
        questionnaires: [
          {
            qType: 'PatientDetails',
            qContent: [
              {
                birthDate: formData.birthDate,
                nric: formData.nric,
                passportCountryCode: formData.passportCountryCode,
                passportNumber: formData.passportNumber,
                gender: formData.gender,
                race: formData.race,
                mobileNumber: formData.primarycaregiverMobileNumber,
                email: formData.primarycaregiverEmail,
                streetAddress: formData.streetAddress,
                zipcode: formData.zipcode,
                daStatus: formData.daStatus,
                daName: formData.daName,
              },
            ],
          },
          {
            qType: 'CaregiverDetails',
            qContent: formTemplate.sections
              .find(section => section.title === "Caregiver's Details")
              ?.fields.map(field => ({
                name: field.name,
                value: formData[field.name],
              })) || [],
          },
          {
            qType: 'DevAssessment',
            qContent: devAssessmtTemplate?.sections.map(section => ({
              title: section.title,
              fields: section.fields.map(field => ({
                name: field.name,
                value: formData[field.name] === true ? "Yes" : formData[field.name] === false || formData[field.name] == null ? "No" : formData[field.name],
                description: field.description,
              })),
            })) || [],
          },
        ],
      };
     // console.log('DataToSend', dataToSend);
  
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/patient/questionnaires`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  

      if (response.status === 200 || response.status === 201) {
        // Set showSuccessAlert to true to display the success alert
        setShowSuccessAlert(true);

        // After 10 seconds, hide the success alert
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 10000);

        // Wait for 20 seconds before reloading the window
        setTimeout(() => {
          window.location.reload();
        }, 20000);
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      // Check if the error response contains validation errors
      if (error.response && error.response.status === 422) {
        const { errors } = error.response.data;
        const errorFromBackend = errors.map((err) => err.msg).join('\n');
        const errorMessage = `Validation Error: ${errorFromBackend}`;
        setErrorMessage(errorMessage);
        setShowErrorAlert(true);

        // After 10 seconds, hide the error alert
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 10000);
      } else {
        console.error('Error submitting data:', error);
      }
    }
  };

  return (
    <div className="min-h-screen py-10 bg-gray-100 flex items-center justify-center">
            {showSuccessAlert && (
        <SuccessAlert
          text="Questionnaire submitted successfully!"
          show={showSuccessAlert}
        />
      )}
      {showErrorAlert && (
        <ErrorAlert text={errorMessage} show={showErrorAlert} />
      )}
      <div className="container max-w-screen-lg mx-auto">
        <img src={cover} alt="logo" className="" />
        <div className="bg-white rounded shadow-lg py-6 px-8">
          <h1 className="text-2xl font-bold mt-10 mb-4 text-black">
            Developmental Assessment
          </h1>

          <form onSubmit={handleSubmit}>
            {formTemplate && (
              <div>
                {formTemplate.sections?.map((section, index) => (
                  <div key={index}>
                                          <h2 className={titleStyle}>{section.title}</h2>
                    <div className="flex flex-wrap -mx-3 mt-2">
                      {renderFields(section.fields)}
                    </div>
                    <hr className="h-px my-4 bg-gray-400 border-0 dark:bg-gray-700" />
                  </div>
                ))}
              </div>
            )}

            <div className="pt-4">
            {renderAgeSection()}

              <label htmlFor="templateType" className={textStyleStrong}>
                Select Your Child's Age for the Appropriate Developmental
                Assessment
              </label>
              <select
                id="templateType"
                value={templateType ?? ''}
                onChange={handleTemplateChange}
                className={inputSelectorStyle('templateType')}
              >
                <option value="">Select an age</option>
                <option value="1-2mths">1 to 2 months</option>
                <option value="3-5mths">3 to 5 months</option>
                <option value="6-12mths">6 to 12 months</option>
                <option value="15-18mths">15 to 18 months</option>
                <option value="2-3yrs">2 to 3 years</option>
                <option value="4-6yrs">4 to 6 years</option>
              </select>
              <hr className="h-px my-10 bg-gray-400 border-0 dark:bg-gray-700" />
            </div>

            {devAssessmtTemplate && (
              <div>
                <div
                  className="mt-2 p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:text-blue-400 dark:bg-gray-800"
                  role="alert"
                >
                  <svg
                    className="flex-shrink-0 inline w-4 h-4 mr-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="font-medium">
                    Please complete the following developmental milestone
                    assessment
                  </span>
                  <p className="text-left pl-7">
                    Only tick "Yes" if your child has achieved the milestone
                  </p>
                </div>
                {devAssessmtTemplate.sections?.map((section, index) => (
                  <div key={index}>
                    <div className="flex-full -mx-3 my-4">
                      <h2 className={titleStyle}>{section.title}</h2>
                      <div className="mx-3 pt-4 text-black font-medium">
                        Yes
                      </div>
                      {section.fields?.map((field, idx) => 
                      field.type === 'checkbox'
                        ? renderCheckbox(field)
                        : renderDropdownDevAssmt([field])
                    )}
                    </div>
                    <hr className="h-px my-4 bg-gray-400 border-0 dark:bg-gray-700" />
                  </div>
                ))}
              </div>
            )}
            <button
              type="submit"
              className="w-full flex-grow text-white bg-gradient-to-r bg-primaryColor-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 ml-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentalAssessment;
