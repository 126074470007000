import React, { useState } from "react";
import axios from "axios";
import { Search } from "lucide-react";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const inputSelectorStyle =
  "mb-4 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";
  const searchResultStyle = "text-sm text-gray-900 dark:text-white";

  const SearchBarPatientQuestionnaire = ({ onPatientDataSelect, setErrorMessage, setEmptyFields }) => {
    const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleSearch = async (searchValue) => {
    // console.log('Searching for:', searchValue);
    setSearchText(searchValue);
    setErrorMessage(""); // Reset error message

    try {
        const response = await axios.get(
            `${REACT_APP_API_BASE_URL}/questionnaires/query?nric=${searchValue.toUpperCase()}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );

        console.log('Response:', response);

        if (response.status === 200) {
            setSearchResults(response.data);
        } else {
            setSearchResults([]);
        }
    } catch (error) {
        console.error("Error searching:", error);
    }
};

const handlePatientSelect = (patient) => {
  if (!patient) {
      console.error("Selected patient data is undefined.");
      return;
  }

  const {
      name,
      nric,
      qUuid,
      qContent
  } = patient;

  // Extract details from the first item in `qContent`, if it exists
  const {
      birthDate,
      gender,
      race,
      mobileNumber,
      email,
      streetAddress,
      zipcode,
      daStatus,
      daName,
  } = qContent?.[0] || {}; // Default to empty object if qContent[0] is undefined

  const patientData = {
      name,
      nric,
      birthDate,
      gender,
      race,
      mobileNumber,
      email,
      streetAddress,
      zipcode,
      daStatus,
      daName,
      qUuid,
  };

  onPatientDataSelect(patientData);
  setSearchText(""); // Clear search text after selection
};

  return (
    <div className="w-full pb-4">
      <label htmlFor="nricSearch" className={textStyle}>
        <Search className="inline-block pr-2 pb-1"/> Search for patient questionnaire by NRIC
        <p className="text-red-500 text-xs pb-4 pl-6">
        * Results are limited to forms submitted within the last 14 days.
        </p>
      </label>
      <input
        id="nricSearch"
        type="text"
        placeholder="Enter NRIC"
        className={inputSelectorStyle}
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
      />

{searchText.length > 0 && (
  <ul className="rounded-lg border dark:border-form-strokedark dark:bg-form-inputborder border-gray-300 absolute z-10 w-1/2 max-h-40 overflow-y-auto">
    {searchResults.map((result) => {
      const createdAtDate = new Date(result.createdAt);
      const localDateTimeString = createdAtDate.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      return (
        <li
          key={result.id}
          className="cursor-pointer bg-gray-2 px-3 py-3 text-black hover:bg-gray-200"
          onClick={() => handlePatientSelect(result)}
        >
          {/* Display search suggestions here */}
          <p className={searchResultStyle}>
            {result.name} ({result.nric}) <strong>Timestamp:</strong> {localDateTimeString}
          </p>
        </li>
      );
    })}
  </ul>
)}

    </div>
  );
};

export default SearchBarPatientQuestionnaire;
