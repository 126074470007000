import React, { useEffect, useState, useRef } from 'react';
import ToolbarButton from '../ToolbarButton';
import { Bold, Italic, List, ListTodo, Link, Heading1, Heading2, Heading3 } from 'lucide-react';
import MarkdownRenderer from './MarkdownRenderer';
import { v4 as uuidv4 } from 'uuid';
import useUserDetails from '../../hooks/useUserDetails';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

interface PostCommentProps {
  onCommentSubmit: () => void; // Change the prop type
  patientId: number; // Define the prop

}

const PostComment: React.FC<PostCommentProps> = ({ onCommentSubmit, patientId }) => {
  const [comment, setComment] = useState('');
  const [userName, setUserName] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [userId, setUserId] = useState('');
  const [activeTab, setActiveTab] = useState('write'); // 'write' or 'preview'
  const textareaRef = useRef(null); // Create a ref for the textarea
  const previewDivRef = useRef<HTMLDivElement | null>(null); // Create a ref for the preview div
  const uuid = uuidv4();
  const {userDetails} = useUserDetails();

  useEffect(() => {
    if (userDetails) {
      setUserName(userDetails.name);
      setUserPicture(userDetails.picture);
      setUserId(userDetails.id);
    }
  },
[]);
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);

    // Automatically adjust the textarea height based on its content
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }

    // Update the height of the preview div
    if (previewDivRef.current) {
      previewDivRef.current.style.height = 'auto';
      previewDivRef.current.style.height = `${previewDivRef.current.scrollHeight}px`;
    }
  };

  const handleSubmit = () => {
    if (comment.trim() !== '') {
      // Define commentData
      const commentData = {
        PatientId: patientId,
        commentType: "HealthReport",
        commentContent: comment,
        commentUuid: uuid,
      };
      // console.log("Data to Post for Comments", commentData);
      // console.log("Comment Data", commentData);
      // Submit the comment and then trigger the refresh callback
      submitComment(commentData);
    }
  };

  const submitComment = (commentData) => {
    // Make a POST request to submit the comment
    fetch(`${REACT_APP_API_BASE_URL}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include credentials (cookies) in the request
      body: JSON.stringify(commentData),
    })
      .then((response) => {
        if (response.ok) {
          // Comment was successfully posted, trigger the callback
          onCommentSubmit();
          setComment('');
        } else {
          console.error('Failed to post comment');
        }
      })
      .catch((error) => {
        console.error('Error posting comment:', error);
      });
  };

  const switchToWriteTab = () => {
    setActiveTab('write');
  };

  const switchToPreviewTab = () => {
    setActiveTab('preview');
  };

  const handleHeading1 = () => {
    const startSyntax = '# ';
    handleMarkdownFormatting(startSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleHeading2 = () => {
    const startSyntax = '## ';
    handleMarkdownFormatting(startSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleHeading3 = () => {
    const startSyntax = '### ';
    handleMarkdownFormatting(startSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleBold = () => {
    const startSyntax = '**';
    const endSyntax = '**';
    handleMarkdownFormatting(startSyntax, endSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleItalic = () => {
    const startSyntax = '_';
    const endSyntax = '_';
    handleMarkdownFormatting(startSyntax, endSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleBullet = () => {
    const startSyntax = '- ';
    handleMarkdownFormatting(startSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleCheck = () => {
    const startSyntax = '- [ ] ';
    const endSyntax = '';
    handleMarkdownFormatting(startSyntax, endSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };

  const handleLink = () => {
    const startSyntax = '[Google';
    const endSyntax = '](https://google.com)';
    handleMarkdownFormatting(startSyntax, endSyntax);

    // Keep the "Write" tab active
    setActiveTab('write');
  };


  const handleMarkdownFormatting = (startSyntax: string, endSyntax: string = '') => {
    if (textareaRef.current) {
      const selectionStart = textareaRef.current.selectionStart;
      const selectionEnd = textareaRef.current.selectionEnd;

      const prefix = comment.substring(0, selectionStart);
      const selectedText = comment.substring(selectionStart, selectionEnd);
      const suffix = comment.substring(selectionEnd);

      const updatedComment = `${prefix}${startSyntax}${selectedText}${endSyntax}${suffix}`;
      setComment(updatedComment);

      // Put the cursor after the newly added Markdown syntax
      textareaRef.current.selectionStart = selectionStart + startSyntax.length;
      textareaRef.current.selectionEnd = selectionStart + startSyntax.length + selectedText.length;
      textareaRef.current.focus();
    }
  };

  // Handle drag and drop for file uploads
  const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    const fileLinks = files.map((file) => `[${file.name}](file://${file.path})`);

    const updatedComment = comment + ' ' + fileLinks.join(' ');
    setComment(updatedComment);
  };
  return (
    <div>
      <div className="rounded-lg dark:border-gray-700">
        <div className="w-full rounded-t-lg border-t border-l border-r dark:border-gray-600">
          <div className="justify-between">
            <div className="flex px-2 py-2">
              <button
                type="button"
                className={`text-sm rounded-t-lg border-t border-l border-r border-gray-300 focus:outline-none px-5 py-1.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ${activeTab === 'write' ? 'bg-white text-gray-900 font-medium' : 'bg-gray-50 text-gray-300'}`}
                onClick={switchToWriteTab}
              >
                Write
              </button>
              <button
                type="button"
                className={`text-sm rounded-t-lg border-t border-l border-r border-gray-300 focus:outline-none px-5 py-1.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover-bg-gray-700 dark:hover-border-gray-600 dark:focus:ring-gray-700 ${activeTab === 'preview' ? 'bg-white text-gray-900 font-medium' : 'bg-gray-50 text-gray-300'}`}
                onClick={switchToPreviewTab}
              >
                Preview
              </button>
              {/* Add your ToolbarButton components here */}
              <ToolbarButton text="H1" icon={<Heading1 className="w-4 h-4" />} onClick={handleHeading1} />
              <ToolbarButton text="H2" icon={<Heading2 className="w-4 h-4" />} onClick={handleHeading2} />
              <ToolbarButton text="H3" icon={<Heading3 className="w-4 h-4" />} onClick={handleHeading3} />
              <ToolbarButton text="Bold" icon={<Bold className="w-4 h-4" />} onClick={handleBold} />
              <ToolbarButton text="Italic" icon={<Italic className="w-4 h-4" />} onClick={handleItalic} />
              <ToolbarButton text="Bullet List" icon={<List className="w-4 h-4" />} onClick={handleBullet} />
              <ToolbarButton text="Check List" icon={<ListTodo className="w-4 h-4" />} onClick={handleCheck} />
              <ToolbarButton text="Link" icon={<Link className="w-4 h-4" />} onClick={handleLink} />
            </div>
          </div>
        </div>
        {activeTab === 'write' ? (
          <textarea
            ref={textareaRef}
            value={comment}
            onChange={handleCommentChange}
            onDrop={handleDrop} // Add the drop event handler
            onFocus={() => setActiveTab('write')}
            placeholder="Write a comment..."
            style={{
              height: "auto",  // Set the initial height to auto
              resize: "none",  // Prevent manual resizing
            }}
            className="w-full flex gap-x-3.5 py-4 px-4 text-sm bg-white border-t-gray-200 rounded-b-lg border-b-gray-200 border-r-gray-200 border-l-gray-200 text-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          />

        ) : (
          <div
            ref={previewDivRef}
            className="w-full h-auto flex gap-x-3.5 py-4 px-4 text-sm bg-white border-t border-l border-r text-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
            <MarkdownRenderer markdownText={comment} />
          </div>
        )}

      </div>
      <div className="pt-4">
        <button
          type="button"
          className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          onClick={handleSubmit}
        >
          Comment
        </button>
      </div>
    </div>
  );
};

export default PostComment;
