// Define the whitelist of allowed IP addresses
const whitelist = ['175.156.139.246','61.11.117.34']; // Example IPs, update as needed

 const ipValidation = async ()=> {
  try {
    // Fetch the IP address from the external service
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    const ip = data.ip;
    // console.log("ip from the user", ip);
    // Check if the fetched IP address matches any in the whitelist
    const isIpAllowed = whitelist.includes(ip);

    return isIpAllowed;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return false;
  }
};

export default ipValidation;
