import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo/logo-colorful.png';
import Footer from '../../components/Footer';
import axios from 'axios'; // Import Axios
import validateForm from '../../function/formValidator'
import formValidationConfig from '../../config/formValidationConfig.json';
import ErrorAlert from "../../components/alerts/ErrorAlert";
import useDisableAutofill from '../../hooks/useDisableAutofill';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const requiredAsteriskStyle = "text-red-500 mx-1";


const SignIn = () => {
  const [email, setEmail] = useState(''); // Define and initialize the email state
  const [errorMessage, setErrorMessage] = useState(''); // State variable for error message
  const [successMessage, setSuccessMessage] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [clinicOptions, setClinicOptions] = useState([]);
  const [userClinics, setUserClinics] = useState([]);
  const [disableButton, setDisableButton] = useState(false); // State variable to disable the button
  const [formErrorMessages, setFormErrorMessages] = useState({ email: undefined });
  const [errorBoxHighlight, setErrorBoxHighlight] = useState({ email: undefined });
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  useDisableAutofill();

  useEffect(() => {
    fetchUserDetailsAndClinics();
  }, []);


  // Check if the credential is valid
  const fetchUserDetailsAndClinics = async () => {
    try {
      const userClinicResponse = await axios.get(`${REACT_APP_API_BASE_URL}/associate`, {
        withCredentials: true,
      });

      const userClinicsData = userClinicResponse.data;

      const clinics = userClinicsData.map(userClinic => ({
        label: userClinic.clinicId,
        value: userClinic.clinicId
      }));

      setClinicOptions(clinics);
      setUserClinics(userClinicsData);
    } catch (error) {
      console.error('Error fetching user details and clinics:', error);

      // Check if the error status is 403 (Forbidden)
      if (error.response && error.response.status === 403) {
        setErrorMessage('Unauthorized: Invalid session ID or session expired.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };


  const inputSelectorStyle = (fieldName) => `
  bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 
  ${errorBoxHighlight[fieldName] ? 'border-red-500' : 'border-gray-300'}
  block w-full`;

  // Handling email field's input change and validate the format
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;

    // Validate the email field using the validateForm function
    const errors = validateForm({ email: emailValue }, formValidationConfig, setFormErrorMessages);

    // No need to update state here

    // Update the email state
    setEmail(emailValue);
  };

  const handleSignIn = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!email) {
      // Email is required but not provided
      setFormErrorMessages({ email: 'Email is missing.' });
      setErrorBoxHighlight({ ...errorBoxHighlight, email: true });
      setAlertMessage('Email is required. Please provide your email address.');
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return;
    }

    try {
      // Find the selected clinic based on clinicId
      const selectedClinic = userClinics.find(clinic => clinic.clinicId === clinicId);

      if (selectedClinic) {
        const dataToSend = {
          clinicId: selectedClinic.id,
          email: email,
        };

        const response = await axios.post(`${REACT_APP_API_BASE_URL}/signin`, dataToSend, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        if (response.status === 200) {
          // Success! Handle the response data here, e.g., redirect
          // console.log("Login Successful!", response.data);
          navigate('/list'); // Redirect to the specific clinic page
        } else {
          console.error('Unexpected response:', response);
          // Handle unexpected response codes
        }
      }
    } catch (error) {
      console.error('Error setting cookie:', error);
      // Handle the error setting clinic cookie
    }
  };


  // Handling sign in when the cookie is not valid and new JWT sesion validation is required
  const handleSecureEmailLink = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!email) {
      // Email is required but not provided
      setFormErrorMessages({ email: 'Email is missing.' });
      setErrorBoxHighlight({ ...errorBoxHighlight, email: true });
      setAlertMessage('Email is required. Please provide your email address.');
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return;
    }

    // Prepare the data to send to the server
    const data = {
      email: email,
    };

    // console.log("data", data);

    try {
      // Make an Axios POST request to send the data to your server
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/signin/createSession`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // User created successfully, display the success message
        setSuccessMessage('Email is sent. Check your inbox for login.');
        setErrorMessage(''); // Clear any previous error message
        setDisableButton(true);
        setTimeout(() => {
          setDisableButton(false);
        }, 60000); // 1 minute (60000 milliseconds)
      } else if (response.status === 400) {
        // Handle a 400 status code (e.g., validation error)
        setErrorMessage(response.data.message);
        setSuccessMessage(''); // Clear any previous success message
      } else if (response.status === 500) {
        // Handle a 400 status code (e.g., validation error)
        setErrorMessage(response.data.message);
        setSuccessMessage(''); // Clear any previous success message
      } else {
        // Handle other status codes (if needed)
        setErrorMessage('No email found. Please contact your clinic adminstrator to create an account.');
        setSuccessMessage('');
      }
    } catch (error) {
      // Handle errors (e.g., display an error message)
      setErrorMessage('No account found. Contact your clinic adminstrator to create an account.');
      setSuccessMessage('');
      console.error('Error creating user:', error);
    }
  };


  return (
    <section className="bg-black dark:bg-gray-900 pb-16 min-h-screen">
      <ErrorAlert
        text={alertMessage}
        show={showErrorAlert} />
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-auto lg:py-6">
        <a href="https://www.milkiway.ai/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
          <img src={logo} alt="logo" className="h-34 w-auto" />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-2 md:space-y-4 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            {successMessage && (
              <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="font-medium pr-2">Success!</span> {successMessage}
              </div>
            )}
            {!errorMessage && !successMessage && (
              <form onSubmit={handleSignIn}>
              <div className="w-full text-left">
                <label className={textStyle}>Clinic Id<span className={requiredAsteriskStyle}>*</span></label>
                <select
                  name="clinicId"
                  value={clinicId}
                  onChange={(e) => setClinicId(e.target.value)}
                  className={inputSelectorStyle('clinicId')}
                >
                  <option value="">Select Clinic</option>
                  {clinicOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              </form>
            )}

            {errorMessage && (
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="font-medium pr-2">Expired session!</span> Log in via a secured email link.
              </div>
            )}
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input type="email" name="email" id="email" value={email} onChange={handleEmailChange}
                  className={inputSelectorStyle('email')} />

                {formErrorMessages.email && (
                  <div className="text-red-500 text-sm">{formErrorMessages.email}</div>
                )}
              </div>
              {!errorMessage && (
                <button
                  className="w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center mr-2 mb-2"
                  onClick={handleSignIn}
                >
                  Sign in
                </button>
              )}

              {errorMessage && (
                <button
                  className="w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center mr-2 mb-2"
                  onClick={handleSecureEmailLink}
                  disabled={disableButton} // Conditionally disable the button
                >
                  Get Link via Email
                </button>
              )}


              <p className="text-sm font-light text-gray-500 dark:text-gray-400 pt-2">
                Don’t have an account yet? <a href="/auth/signup" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </section>

  );
};

export default SignIn;