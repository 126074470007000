import moment from 'moment';

/**
 * Converts a UTC timestamp to Singapore Time (SGT).
 * @param utcTimestamp - The UTC timestamp to be converted.
 * @returns A formatted date string in the format 'YYYY-MM-DD' or 'Invalid Date' if the input is invalid.
 */
const convertUTCToSGTimestamp = (utcTimestamp) => {
  if (!utcTimestamp) return 'Invalid Date'; // Handle missing or invalid date

  const utcMoment = moment.utc(utcTimestamp);
  
  // Check if the moment object is valid
  if (!utcMoment.isValid()) {
    console.error('Invalid UTC Timestamp:', utcTimestamp);
    return 'Invalid Date'; // Return 'Invalid Date' if the timestamp is not valid
  }

  const sgMoment = utcMoment.utcOffset(8); // UTC+8 for Singapore Timezone
  return sgMoment.format('YYYY-MM-DD'); // Return formatted date string
};

export default convertUTCToSGTimestamp;
