import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface TabItem {
  id: string;
  label: string;
  isActive: boolean;
}

interface TabsProps {
  uuid: string;
}

const Tabs: React.FC<TabsProps> = ({ uuid }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveTab = (): string => {
    const paths = location.pathname.split("/");
    if (paths[2] === "screening") {
      return "screening";
    }
    if (paths[2] === "labs") {
      return "labs";
    }
    if (paths[2] === "testsprocedures") {
      return "testsprocedures";
    }
    if (paths[2] === "report") {
      return "report";
    }
    if (paths[2] === "details") {
      return "details";
    }
    return "patient";
  };

  const activeTab = getActiveTab();

  const handleTabClick = (id: string) => {
    if (id === "patient") {
      navigate(`/patient/${uuid}`);
    } else {
      navigate(`/patient/${id}/${uuid}`);
    }
  };

  const tabItems: TabItem[] = [
    { id: "patient", label: "Patient", isActive: activeTab === "patient" },
    { id: "screening", label: "Screening", isActive: activeTab === "screening" },
    { id: "labs", label: "Labs", isActive: activeTab === "labs" },
    { id: "testsprocedures", label: "Tests/Procedures", isActive: activeTab === "testsprocedures" },
    { id: "report", label: "Report", isActive: activeTab === "report" },
    { id: "details", label: "Details", isActive: activeTab === "details" },
  ];

  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:border-gray-700 dark:text-gray-400 justify-end">
      {tabItems.map((tabItem) => (
        <li className="mr-2" key={tabItem.id}>
<button
            onClick={() => handleTabClick(tabItem.id)}
            className={`inline-block p-2 w-32 ${tabItem.isActive ? "hover:text-white hover:bg-primaryColor-500 text-primaryColor-500 bg-white rounded-t-lg dark:hover:text-white dark:hover:bg-primaryColor-500 dark:text-white dark:bg-grayColor-700 active" 
            : "rounded-t-lg text-white bg-grayColor-300 hover:text-white hover:bg-primaryColor-500 dark:hover:text-white dark:bg-boxdark dark:hover:bg-gray-800"
            } flex justify-center items-center`}
          >
            <div className="flex items-center">
              <span>{tabItem.label}</span>
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
