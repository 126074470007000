import React, { useState, useEffect } from 'react';
import step2 from '../../images/cover/step2.svg';
import axios from 'axios';
import {
  CardHeader,
  Typography,
} from "@material-tailwind/react"
import sanitizeInput from '../../function/sanitizeInput'
import validateForm from '../../function/formValidator'
import validateMissingFields from '../../function/missingFieldValidator';
import formValidationConfig from '../../config/formValidationConfig.json';
import ErrorAlert from "../alerts/ErrorAlert";
import SuccessAlert from '../alerts/SuccessAlert';


// Define FormData interface
interface FormData {
  clinicName: string,
  clinicPhoneNumber: string,
  clinicEmail: string,
  clinicAddress: string,
  clinicZipcode: string,
  clinicCountry: string,
}

type FormErrorMessages = Partial<FormData>;


const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const inputSelectorStyle =
  "bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";
const requiredAsteriskStyle = "text-red-500 mx-1";


const StepTwoForm = ({ clinicId: propClinicId, invitationCode: propInvitationCode, onNext, onPrev }) => {
  const [clinicId, setClinicId] = useState('');
  const [invitationCode, setInvitationCode] = useState('');

  const [formData, setFormData] = useState({
    clinicName: '',
    clinicPhoneNumber: '',
    clinicEmail: '',
    clinicAddress: '',
    clinicZipcode: '',
    clinicCountry: 'Singapore',
  });
  const [formErrorMessages, setFormErrorMessages] = useState<FormErrorMessages>({
    clinicName: undefined,
    clinicPhoneNumber: undefined,
    clinicEmail: undefined,
    clinicAddress: undefined,
    clinicZipcode: undefined,
    clinicCountry: undefined,
  });
  const [errorBoxHighlight, setErrorBoxHighlight] = useState({
    clinicName: false,
    clinicPhoneNumber: false,
    clinicEmail: false,
    clinicAddress: false,
    clinicZipcode: false,
    clinicCountry: false,
  
  });

  const [success, setSuccess] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const savedClinicId = localStorage.getItem('clinicId');
    const savedInvitationCode = localStorage.getItem('invitationCode');

    if (savedClinicId) {
      setClinicId(savedClinicId);
    } else if (propClinicId) {
      setClinicId(propClinicId);
    }

    if (savedInvitationCode) {
      setInvitationCode(savedInvitationCode);
    } else if (propInvitationCode) {
      setInvitationCode(propInvitationCode);
    }
  }, [propClinicId, propInvitationCode]);


  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('Step2ClinicDetails')) || {};
    setFormData((prevFormData) => ({ ...prevFormData, ...storedData }));
  }, []);



  const inputSelectorStyle = (fieldName, readOnly) => `
  ${readOnly ? 'p-0 bg-white border-b-white border-t-white border-r-white border-l-white  focus:ring-white focus:border-white' : 'bg-gray-50'} border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 
  ${errorBoxHighlight[fieldName] ? 'border-red-500' : 'border-gray-300'}
  block w-full`;
  

const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Update the state based on the input field name
  const updatedFormData = { ...formData, [name]: value };
  setFormData(updatedFormData);

  // Clear the error message for the current field when it changes
  setFormErrorMessages({ ...formErrorMessages, [name]: "" });

  // Clear errorBoxHighlight for the current field
  setErrorBoxHighlight({ ...errorBoxHighlight, [name]: false });

  // Validate form fields directly without delay
  validateForm(updatedFormData, formValidationConfig, setFormErrorMessages, Object.keys(formData));

  // Additional logic to clear validation check if input is in the right format
  const fieldConfig = formValidationConfig.fields.find(field => field.name === name);
  if (fieldConfig && fieldConfig.regex && new RegExp(fieldConfig.regex).test(value)) {
    setFormErrorMessages({ ...formErrorMessages, [name]: "" });
  }

  //setErrorBoxHighlight({ ...errorBoxHighlight, [name]: !formErrorMessages[name] });

};

  const handleSubmit = async (e) => {
    e.preventDefault();

  // Call validateMissingFields to check for missing fields
  const fieldsToCheck = Object.keys(formData); // Get the field names from the formData object
  const missingFields = validateMissingFields(formData, formValidationConfig, fieldsToCheck);

  // Check if there are any missing fields
  if (missingFields.length > 0) {
    // Display alert for missing fields
    const errorObject = {};
    missingFields.forEach(field => {
      errorObject[field.name] = `${field.label} is required.`;
    });
    setFormErrorMessages(errorObject);

    // Highlight the input fields with missing data
    const updatedErrorBoxHighlight = { ...errorBoxHighlight }; // Create a copy of the current state
    fieldsToCheck.forEach(field => {
      updatedErrorBoxHighlight[field] = missingFields.some(missingField => missingField.name === field);
    });
    setErrorBoxHighlight(updatedErrorBoxHighlight);

    setAlertMessage('Missing fields. Please fill out required fields before submission.')
    setShowErrorAlert(true);
    setTimeout(() => {
      setShowErrorAlert(false);
    }, 5000);
    return;
  }

         const dataToSend = {
          clinicName: formData.clinicName,
          clinicPhoneNumber: formData.clinicPhoneNumber,
          clinicZipcode: formData.clinicZipcode,
          clinicAddress: formData.clinicAddress,
          clinicEmail: formData.clinicEmail,
          clinicCountry: formData.clinicCountry,
          clinicId: clinicId,
          invitationCode: invitationCode,
        };
    
        try {
          const response = await axios.post(
            `${REACT_APP_API_BASE_URL}/createclinic`,
            dataToSend,
            {
              headers: {
                'Content-Type': 'application/json',
              }
            }
          );
    
          if (response.status === 201 || response.status === 200) {
            const clinicKey = response.data.clinicKey;
            setSuccess(true);
            onNext(clinicId, clinicKey);
          } else {
            // Handle other response statuses if needed
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            // Extract error messages from validation errors
            const validationErrors = error.response.data.errors;
            const errorMessages = validationErrors.map(error => error.msg);
    
            // Display error messages to the user
            alert('The form has failed our valdiation check. Please check your fields again. ' + errorMessages.join(', '));
          } else {
            console.error('Error submitting data:', error);
            // Display a generic error message to the user
            alert('An error occurred while submitting data. Please try again later.');
          }
        }
    
        localStorage.setItem('Step2ClinicDetails', JSON.stringify(dataToSend)); 
  };


  const handleBack = (e) => {
    e.preventDefault();
    onPrev();
  };


  return (
    <>
      <div className="space-y-4 md:space-y-6 sm:p-8 text-center">
        <ErrorAlert
          text={alertMessage}
          show={showErrorAlert} />
        <SuccessAlert
          text="Form saved."
          show={success} />
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src={step2}
            alt="card-image"
          />
        </CardHeader>
        <Typography variant="small" color="black" className="text-left">
          Step 2 of 3
        </Typography>
        <div>
          <p className="text-left font-bold text-title-lg text-black pb-2">
            Please fill out the details of your clinic.
          </p>
          <p className="pb-2 text-base text-gray-900 dark:text-white text-left">
            This is used for patients to reach your clinic in the event that there are any questions.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-6 space-y-4">
            {/* Form input fields */}
            {Object.keys(formData).map((key) => (
              <div key={key}>
                <label htmlFor={key} className="text-start block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()} <span className={requiredAsteriskStyle}>*</span>
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={sanitizeInput(formData[key])}
                  onChange={handleInputChange}
                  readOnly={key === 'clinicId' || key === 'invitationCode'} // Set readOnly based on the field name
                  className={inputSelectorStyle(key, key === 'clinicId' || key === 'invitationCode')}
                  />
                {formErrorMessages[key] && (
                  <div className="text-red-500 text-sm text-start">{formErrorMessages[key]}</div>
                )}
              </div>
            ))}


          </div>

          {/* Back and Next buttons */}
          <div className="flex flex-row">
            <button
              onClick={handleBack}
              className="w-1/2 flex-grow text-gray-600 border border-gray-600 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 mr-2"
            >
              Back
            </button>
            <button
              type="submit"
              className="w-1/2 flex-grow text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 ml-2"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default StepTwoForm;
