import React, { useState } from 'react';
import { File } from 'lucide-react';
import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface PatientFile {
    id: number;
    fileName: string;
    fileType: string;
    fileId: string;
    uploadCompleteTime: string | null;
    createdAt: string;
    updatedAt: string;
    PatientId: number;
}

interface FilesProps {
    patientId: number;
    files: PatientFile[];
}

const getDownloadUrl = async ({ patientId, fileId }: { patientId: number; fileId: string }): Promise<string | null> => {
    try {
        const resp = await axios.get(
            `${REACT_APP_API_BASE_URL}/patients/${patientId}/files/id/${fileId}`,
            {
                withCredentials: true, // Include credentials (cookies) in the request
            }
        );
        if (resp.status === 404) {
            console.error('Data not found for this patient.');
            // You can return or set some state to indicate that no data was found.
            return null;
        }
        if (!resp || !resp.data) throw new Error('Missing data');
        return resp.data.signedUrl;
    } catch (err) {
        console.error('Error:', err);
        return null;
    }
};

const Labs: React.FC<FilesProps> = ({ patientId, files }) => {
    // console.log('files received from report', files);
    const [displayedFiles, setDisplayedFiles] = useState(5); // Number of files to initially display

    const renderFiles = files.slice(0, displayedFiles);

    const loadMore = () => {
        setDisplayedFiles(prevDisplayedFiles => prevDisplayedFiles + 5); // Load additional 5 files
    };

    return (
        <div>
            {renderFiles.map(({ fileName, fileId }) => (
                <button
                    key={fileId}
                    onClick={async () => {
                        const downloadUrl = await getDownloadUrl({ patientId, fileId });
                        if (downloadUrl) {
                            window.open(downloadUrl, '_blank');
                        } else {
                            console.error('Failed to get download URL');
                        }
                    }}
                    className="mb-4 flex items-center w-full p-4 text-gray-500 bg-white rounded-lg border dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600 hover:text-blue-500 hover:underline"
                >
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
                        <File className="w-4 h-auto" />
                        <span className="sr-only">File icon</span>
                    </div>
                    <div className="ml-3 text-sm font-normal text-start">{fileName}</div>
                </button>
            ))}
            {files.length > displayedFiles && ( // Render "Load More" button if there are more files to load
                <button onClick={loadMore} className="bg-primaryColor-500 text-white py-2 px-4 rounded-lg hover:underline">
                    Load More
                </button>
            )}
        </div>
    );
};

export default Labs;
