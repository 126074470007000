import React from 'react';

// Define the structure for each eye's tonometry result
interface TonometryResult {
    type: string; // Type of the form (e.g., "Tonometry")
    rightEye: number; // Result for the right eye
    leftEye: number; // Result for the left eye
    rightAssessment: string; // Assessment for the right eye
    leftAssessment: string; // Assessment for the left eye
}

// Update TonometryProps to accept an array of TonometryResult
interface TonometryProps {
    tonometryData: TonometryResult[]; // Accept an array of objects
}

const Tonometry: React.FC<TonometryProps> = ({ tonometryData }) => {
    // Access the first element of the array
    const data = tonometryData; // Assuming there's always at least one entry

    return (
        <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Tonometry Report</h3>
            <div className="space-y-4">
                <div className="border border-gray-200 p-4 rounded-md">
                    <h4 className="text-lg font-medium text-gray-700">Right Eye</h4>
                    <p className="text-gray-600">Pressure: {data[0].rightEye} mmHg</p>
                    <p className="text-gray-600">Assessment: {data[0].rightAssessment}</p>
                </div>
                <div className="border border-gray-200 p-4 rounded-md">
                    <h4 className="text-lg font-medium text-gray-700">Left Eye</h4>
                    <p className="text-gray-600">Pressure: {data[0].leftEye} mmHg</p>
                    <p className="text-gray-600">Assessment: {data[0].leftAssessment}</p>
                </div>
            </div>
        </div>
    );
};

export default Tonometry;
