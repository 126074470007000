import { useState, useEffect } from 'react';
import calculateAge from '../function/calculateAge';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useVisitAndPatientData = (uuid) => {
  const [visitData, setVisitData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [linkActive, setLinkActive] = useState(null); // Initialize linkActive state as null

  useEffect(() => {
    const fetchVisitAndPatientData = async () => {
      setLoading(true);
      try {
        const visitResponse = await fetch(`${REACT_APP_API_BASE_URL}/visit/uuid/${uuid}`, {
          credentials: 'include', // Include credentials (cookies) in the request
        });
        if (visitResponse.ok) {
          const visitData = await visitResponse.json();
          setVisitData(visitData);

          const patientId = visitData.PatientId;
          const patientResponse = await fetch(`${REACT_APP_API_BASE_URL}/patients/id/${patientId}`, {
            credentials: 'include', // Include credentials (cookies) in the request
          });
          if (patientResponse.ok) {
            const patientData = await patientResponse.json();
            // Calculate age from birthDate
            const age = calculateAge(patientData.birthDate);
            // Include age in patientData
            const updatedPatientData = { ...patientData, age };
            setPatientData(updatedPatientData);
          }
        }
        
       // console.log('Fetched visitData:', visitData);
       // console.log('Fetched linkActive:', visitData?.linkActive);
        
        // Set linkActive state
        setLinkActive(visitData?.linkActive || false);
      } catch (error) {
        console.error('Error fetching patient and visit data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (uuid) {
      fetchVisitAndPatientData();
    }
  }, [uuid]);

  return { visitData, patientData, loading, linkActive, setVisitData, setLinkActive };
};

export default useVisitAndPatientData;
