import React from 'react';

// Define the structure for the colonoscopy report props
interface ColonoscopyProps {
    colonoscopyData: {
        bppsScore: string; // BPPS score
        pathologyDetailsTable: Array<any>; // Adjust the type as per your expected structure for pathology details
        report: string; // Detailed report
    };
}

// Function to convert markdown to HTML
const parseMarkdownToHTML = (markdown: string) => {
    let html = markdown;

    // Replace headers (e.g., ### Header)
    html = html.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    html = html.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    html = html.replace(/^# (.*$)/gim, '<h1>$1</h1>');

    // Replace bold (e.g., **bold**)
    html = html.replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>');

    // Replace italic (e.g., *italic* or _italic_)
    html = html.replace(/\*(.*)\*/gim, '<em>$1</em>');
    html = html.replace(/_(.*)_/gim, '<em>$1</em>');

    // Replace unordered lists (e.g., - Item)
    html = html.replace(/^\- (.*$)/gim, '<ul><li>$1</li></ul>');

    // Replace ordered lists (e.g., 1. Item)
    html = html.replace(/^\d+\. (.*$)/gim, '<ol><li>$1</li></ol>');

    // Replace double line breaks with paragraph tags
    html = html.replace(/\n\n/gim, '</p><p>');

    // Replace single line breaks with <br />
    html = html.replace(/\n/gim, '<br />');

    // Wrap in paragraph if not already
    if (!html.startsWith('<p>')) {
        html = `<p>${html}</p>`;
    }

    return html.trim(); // Return trimmed HTML
};

const Colonoscopy: React.FC<ColonoscopyProps> = ({ colonoscopyData }) => {
    const { report } = colonoscopyData;

    // Convert the markdown report to HTML
    const htmlContent = parseMarkdownToHTML(report);

    return (
        <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-bold text-gray-900">Colonoscopy Report</h3>

            <div className="mb-4">
                <div
                    className="text-gray-700 text-sm leading-relaxed tracking-wide"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            </div>
        </div>
    );
};

export default Colonoscopy;
