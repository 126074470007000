import { useState, useEffect } from 'react';
import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const preprocessSnomedCode = (snomedCodeObject) => {
  // Convert the object into an array of entries
  const entries = Object.entries(snomedCodeObject);
  
  // Map each entry to an object with code and description properties
  const snomedCodeArray = entries.map(([code, description]) => ({ code, description }));

  return snomedCodeArray;
};

const useFormData = (uuid) => {
  const [pastMedicalHistory, setPastMedicalHistory] = useState(null);
  const [snomedCode, setSnomedCode] = useState(null);
  const [lifestyleHistory, setLifestyleHistory] = useState(null);
  const [familyHistory, setFamilyHistory] = useState(null);
  const [internalNotes, setInternalNotes] = useState(null);
  const [physicalExam, setPhysicalExam] = useState(null);
  const [labs, setLabs] = useState(null);

  const [matchedLabConditions, setmatchedLabConditions]= useState(null);

  const [recommendation, setRecommendation] = useState(null);

  const [verifiedAITags, setverifiedAITags] = useState(null);

  const [vision, setVision] = useState(null);
  const [visionCreatedDate, setVisionCreatedDate] = useState(null);

  const [tonometry, setTonometry] = useState(null);
  const [tonometryCreatedDate, setTonometryCreatedDate] = useState(null);

  const [audiogram, setAudiogram] = useState(null);
  const [audiogramCreatedDate, setAudiogramCreatedDate] = useState(null);

  const [colonoscopy, setColonoscopy] = useState(null);
  const [colonoscopyCreatedDate, setColonoscopyCreatedDate] = useState(null);

  const [framingham, setFramingham] = useState(null); 
  const [framinghamCreatedDate, setFraminghamCreatedDate] = useState(null);

  const [loadingFormData, setLoadingFormData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/forms/uuid/${uuid}`, {
          withCredentials: true, // Include credentials
          headers: {
            'Content-Type': 'application/json', // Set your headers if needed
            // Add any other necessary headers
          },
        });

        const { data } = response;
        // console.log('Form data fetched from hook', data);

        const pmHxEntry = data.find((entry) => entry.formType === 'PastMedicalHx');
        if (pmHxEntry) {
          setPastMedicalHistory(pmHxEntry.formContent.pastMedicalHistory);
        }

        const snomedEntry = data.find((entry) => entry.formType === 'PastMedicalHx');
        if (snomedEntry) {
          setSnomedCode(snomedEntry.formContent.snomedCodeDetails);
        }

        const lifestyleHxEntry = data.find((entry) => entry.formType === 'LifestyleHx');
        if (lifestyleHxEntry) {
          setLifestyleHistory(lifestyleHxEntry.formContent);
        }

        const fmHxEntry = data.find((entry) => entry.formType === 'FamilyHx');
        if (fmHxEntry) {
          setFamilyHistory(fmHxEntry.formContent);
        }

        const internalNotesEntry = data.find((entry) => entry.formType === 'InternalNotes');
        if (internalNotesEntry) {
          setInternalNotes(internalNotesEntry.formContent);
        }

        const physicalExamEntry = data.find((entry) => entry.formType === 'PhysicalExam');
        if (physicalExamEntry) {
          setPhysicalExam(physicalExamEntry.formContent);
        }

        const labEntry = data.find((entry) => entry.formType === 'Labs');
        if (labEntry) {
          setLabs(labEntry.formContent);
        }

        const recommendationEntry = data.find((entry) => entry.formType === 'Recommendation');
        if (recommendationEntry) {
          // console.log("Recommendation Entry Content:", recommendationEntry.formContent);
          setRecommendation(recommendationEntry.formContent);
        }

        const matchedLabConditionsEntry = data.find((entry) => entry.formType === 'ProcessedLabConditions');
        if (matchedLabConditionsEntry) {
          // console.log("matchedLabConditionsEntry Entry Content:", matchedLabConditionsEntry.formContent);
          setmatchedLabConditions(matchedLabConditionsEntry.formContent);

        }

        const verifiedAITagsEntry = data.find((entry) => entry.formType === 'VerifiedAIRecTags');
        if (verifiedAITagsEntry) {
          setverifiedAITags(verifiedAITagsEntry.formContent);
        }
        
        const VisionEntry = data.find((entry) => entry.formType === 'Vision');
        if (VisionEntry) {
          setVision(VisionEntry.formContent);
          setVisionCreatedDate(data.find(entry => entry.formType === 'Vision')?.createdAt || null);
        }

        const tonometryEntry = data.find((entry) => entry.formType === 'Tonometry');
        if (tonometryEntry) {
          setTonometry(tonometryEntry.formContent);
          setTonometryCreatedDate(data.find(entry => entry.formType === 'Tonometry')?.createdAt || null);
        }

        const audiogramEntry = data.find((entry) => entry.formType === 'Audiogram');
        if (audiogramEntry) {
          setAudiogram(audiogramEntry.formContent);
          setAudiogramCreatedDate(data.find(entry => entry.formType === 'Audiogram')?.createdAt || null);
        }

        const colonoscopyEntry = data.find((entry) => entry.formType === 'Colonoscopy');
        if (colonoscopyEntry) {
          setColonoscopy(colonoscopyEntry.formContent);
          setColonoscopyCreatedDate(data.find(entry => entry.formType === 'Colonoscopy')?.createdAt || null);
        }

        const fraiminghamEntry = data.find((entry) => entry.formType === 'Framingham');
        if (fraiminghamEntry) {
          setFramingham(fraiminghamEntry.formContent);
          setFraminghamCreatedDate(data.find(entry => entry.formType === 'Framingham')?.createdAt || null);
        }


        setLoadingFormData(false);
      } catch (error) {
        console.error(error);
        setLoadingFormData(false);
      }
    };

    fetchData();
    // console.log("recommendation from hook", recommendation);
    // console.log("Hook internalNotes", internalNotes);

  }, [uuid]);

  return {pastMedicalHistory, 
    snomedCode,
    lifestyleHistory, 
    familyHistory, 
    internalNotes,
    physicalExam, 
    labs, 
    recommendation, 
    matchedLabConditions,
    verifiedAITags,
    vision, 
    visionCreatedDate,
    tonometry, 
    tonometryCreatedDate,
    audiogram, 
    audiogramCreatedDate,
    colonoscopy, 
    colonoscopyCreatedDate,
    framingham,
    framinghamCreatedDate,
    loadingFormData };
};

export default useFormData;