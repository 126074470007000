import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
import axios from 'axios';
import CardQuestionnaire from '../cards/CardQuestionnaire';
import useTemplate from '../../hooks/useTemplate';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const textStyle = 'text-sm font-medium text-gray-900 dark:text-white';
const requiredAsteriskStyle = 'text-red-500 mx-1'; // Define the style for the red asterisk
const inputSelectorStyle =
  'mb-4 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500';

interface ModalAddVisitProps {
  openAddVisitModal: () => void; // Specify the type for openAddVisitModal
  closeAddVisitModal: () => void; // Specify the type for closeAddVisitModal
  patientId: string;
  name: string;
  nric: string;
}

const ModalAddVisit: React.FC<ModalAddVisitProps> = ({
  openAddVisitModal,
  closeAddVisitModal,
  patientId,
  name,
  nric,
}) => {
  const [healthScreeningPackageUuid, setHealthScreeningPackageUuid] = useState('');
  const [success, setSuccess] = useState(false);
  const [healthScreeningPackageOptions, setHealthScreeningPackageOptions] = useState([]);
  const [qUuid, setqUuid] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null); // State variable to store the selected patient data
  const { templateData, loading } = useTemplate(); // Use the useTemplate hook

  useEffect(() => {
    if (!loading && templateData['HealthScreeningPackage']) {
      setHealthScreeningPackageOptions(templateData['HealthScreeningPackage']);
      setSelectedPatient(null); // Reset selectedPatient state when the modal is opened or closed
    }
  }, [templateData, loading]);

  const handleSubmit = async () => {
    const generatedUuid = qUuid || uuidv4();

    const visit = {
      uuid: generatedUuid,
      healthScreeningPackageUuid: healthScreeningPackageUuid,
      PatientId: patientId,
      qAssociated: !!qUuid, // Set qAssociated to true if qUuid is available
    };
    // console.log('visit to be Sent', visit);

    try {
      // API call to create a visit row
      const visitResponse = await axios.post(
        `${REACT_APP_API_BASE_URL}/visit`,
        visit, // Send data instead of 'visit'
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );

      // Handle the response for the visit POST request here
      if (visitResponse.status === 201) {
        // console.log("Visit data submitted successfully");

        // Reset insurance package after successful visit submission
        setHealthScreeningPackageUuid('');

        closeAddVisitModal(); // Close the modal
      } else {
        // Handle other status codes for the visit request
        console.error(
          'Received an unexpected status code for the visit request:',
          visitResponse.status,
        );
      }
    } catch (error) {
      // Handle any errors for the visit request here
      console.error('Error submitting visit data:', error);
    }
  };

  const onPatientDataSelect = (patientData) => {
    setqUuid(patientData ? patientData.qUuid : null); // Set qUuid based on the selected patient data, handling null case
    // console.log("qUuid pass from child", patientData ? patientData.qUuid : null); // Log the updated qUuid
  };

  return (
    <div
      className={`fixed inset-0 ${openAddVisitModal ? '' : 'hidden'}`}
      style={{ zIndex: 1000 }}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-black bg-opacity-75 absolute inset-0 z-10"></div>

        <div className="w-1/2 bg-white p-8 rounded-lg z-20 space-y-6 dark:bg-gray-800">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              Add Visit
            </h2>
            <button
              onClick={closeAddVisitModal}
              className="text-gray-500 hover:text-primary-500 cursor-pointer relative bottom-8 left-2"
            >
              <X className="relative top-4 right-2 w-4 h-4" />
            </button>
          </div>

          <div className="">
            <div>
              <label className={textStyle}>Name:</label>
              <p className="pl-2 inline">{name}</p>
            </div>
            <div>
              <label className={textStyle}>NRIC:</label>
              <p className="pl-2 inline">{nric}</p>
            </div>
          </div>

          {success && (
            <div
              className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="font-medium">Success!</span> Visit created
            </div>
          )}

          {/* List of Questionnaire found */}
          <div className="w-full col-span-1">
            <h3 className="text-base font-bold text-gray-900 dark:text-white">
              Questionnaire(s) found:
            </h3>
            <p className="text-red-500 text-xs pb-2">
              * Results are limited to forms submitted within the last 14 days.
            </p>
            <CardQuestionnaire
              nric={nric}
              onPatientDataSelect={onPatientDataSelect}
            />
          </div>

          <div>
            <label className={textStyle}>
              Health Screening Package
              <span className={requiredAsteriskStyle}>*</span>
            </label>
            <select
              name="healthScreeningPackage"
              value={healthScreeningPackageUuid} // Assuming this state is managed
              onChange={(e) => setHealthScreeningPackageUuid(e.target.value)}
              className={inputSelectorStyle}
              required
            >
              <option value="">Select a package</option> {/* Placeholder */}
              {healthScreeningPackageOptions.length > 0 ? ( // Check if there are any options
                healthScreeningPackageOptions
                  /* .sort((a, b) => {
        const packageA = a.healthScreeningPackageName.toLowerCase();
        const packageB = b.healthScreeningPackageName.toLowerCase();
        if (packageA < packageB) return -1;
        if (packageA > packageB) return 1;
        return 0;
      }) */
                  .map((option) => (
                    <option
                      key={option.healthScreeningPackageUuid}
                      value={option.healthScreeningPackageUuid}
                    >
                      {option.healthScreeningPackageName}{' '}
                      {/* Display only the package name */}
                    </option>
                  ))
              ) : (
                <option disabled>No packages available</option> // Option when no packages are available
              )}
            </select>
          </div>

          <div className="flex items-center space-x-4 pt-8">
            <button
              type="button"
              onClick={handleSubmit}
              className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover-bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark-focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddVisit;
