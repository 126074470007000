import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const textStyle = 'text-sm text-black dark:text-white mr-4';
const inputCheckboxStyle =
  'mr-4 inline-block w-4 bg-white border border-gray-300 text-gray-900 text-base rounded focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500';
const requiredAsteriskStyle = 'text-red-500 mx-1';
const inputRadioStyle =
  'mr-4 inline-block w-4 h-4 bg-white border border-gray-300 text-gray-900 text-base rounded-full focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500';

const HeartFailureQuestionnaire: React.FC = () => {

    const inputSelectorStyle = (fieldName) => `
    bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 
    block w-full`;

    const { clinicId } = useParams<{ clinicId: string }>();

  const [formData, setFormData] = useState({
    name:'',
    nric:'',
    lastAppointmentDate: '',
    specialistSeen: false,
    specialistType: '',
    admittedToHospital: false,
    emergencyVisit: false,
    walking: false,
    housework: false,
    gardening: false,
    otherActivity: false,
    structuredExerciseProgram: false,
    pillowsForSleep: '',
    energyLevelChange: '',
    shortnessOfBreathChange: '',
    chestPainChange: '',
    heartRacing: '',
    shortnessOfBreathAtNight: '',
    lightheadedOrDizzy: '',
    swelling: { feet: false, ankles: false, legs: false, stomach: false },
    extraWaterPills: '',
    conditionChange: '',
    fluidRestriction: '',
    problems: {
      littleInterest: false,
      feelingDown: false,
      feelingAnxious: false,
      worrying: false,
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // Check if the event target is an HTMLInputElement and if so, access the checked property
    if (type === 'checkbox' && e.target instanceof HTMLInputElement) {
      const checked = e.target.checked;
      // Handle checkbox change here by updating the formData state accordingly
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
      }));
      // console.log(`Checkbox ${name} checked: ${checked}`);
    } else if (type === 'radio' && e.target instanceof HTMLInputElement) {
      const radioValue = e.target.value;
      // Handle radio button change here by setting its value to the form data
      setFormData(prevData => ({
        ...prevData,
        [name]: radioValue
      }));
      // console.log(`Radio button ${name} selected: ${radioValue}`);
    } else {
      // Handle other form elements
      // console.log(`Form element ${name} changed: ${value}`);
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };
  
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSend = {
        clinicId: clinicId,
        name: formData.name,
        nric: formData.nric.toUpperCase(),
        questionnaires: [
          {
            qType: 'HeartFailure',
            qContent: {
              lastAppointmentDate: formData.lastAppointmentDate,
              specialistSeen: formData.specialistSeen,
              specialistType: formData.specialistType || '',
              admittedToHospital: formData.admittedToHospital || false,
              emergencyVisit: formData.emergencyVisit || false,
              walking: formData.walking,
              housework: formData.housework,
              gardening: formData.gardening,
              otherActivity: formData.otherActivity,
              structuredExerciseProgram: formData.structuredExerciseProgram || false,
              pillowsForSleep: formData.pillowsForSleep || '',
              energyLevelChange: formData.energyLevelChange || '',
              shortnessOfBreathChange: formData.shortnessOfBreathChange || '',
              chestPainChange: formData.chestPainChange || '',
              heartRacing: formData.heartRacing || '',
              shortnessOfBreathAtNight: formData.shortnessOfBreathAtNight || '',
              lightheadedOrDizzy: formData.lightheadedOrDizzy || '',
              swelling: formData.swelling || {feet: false, ankles: false, legs: false, stomach: false },
              extraWaterPills: formData.extraWaterPills || '',
              conditionChange: formData.conditionChange || '',
              fluidRestriction: formData.fluidRestriction || '',
              problems: formData.problems || {
                littleInterest: false,
                feelingDown: false,
                feelingAnxious: false,
                worrying: false,
              },
            },
          },
        ],
      };
  
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/patient/questionnaires`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // console.log(response.data); // Handle response as needed
      if (response.status === 200 || 201) {
        // console.log('Data submitted successfully:', response.data);
        // Handle success as needed
      } else {
        console.error('Error submitting data:', response.status);
        // Handle error status code
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      // Handle other errors
    }
  };
  

  return (
    <div className="min-h-screen py-10 bg-gray-100 flex items-center justify-center">
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      
    <div className="mb-4">
        <label 
        className={textStyle}
        htmlFor="lastAppointmentDate">
          Full Name
        </label>
        <input
        className={inputSelectorStyle('name')}
                      id="name"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className={textStyle} htmlFor="lastAppointmentDate">
          NRIC
        </label>
        <input
        className={inputSelectorStyle('nric')}
        id="nric"
          type="text"
          name="nric"
          value={formData.nric}
          onChange={handleChange}
        />
      </div>
      
      <div className="mb-4">
        <label className={textStyle} htmlFor="lastAppointmentDate">
          Since your last doctor's appointment on
        </label>
        <input
        className={inputSelectorStyle('lastAppointmentDate')}
        id="lastAppointmentDate"
          type="text"
          placeholder="Date"
          name="lastAppointmentDate"
          value={formData.lastAppointmentDate}
          onChange={handleChange}
        />
      </div>

      {/* Specialist Seen */}
      <div className="mb-4">
        <label className={textStyle}>
          Did you see a Specialist?
        </label>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="specialistSeenYes"
            name="specialistSeen"
            value="true"
            checked={formData.specialistSeen === true}
            onChange={handleChange}
            className={inputCheckboxStyle}
          />
          <label htmlFor="specialistSeenYes" className={textStyle}>
            Yes
          </label>
          <input
            type="checkbox"
            id="specialistSeenNo"
            name="specialistSeen"
            value="false"
            checked={formData.specialistSeen === false}
            onChange={handleChange}
            className={inputCheckboxStyle}
          />
          <label htmlFor="specialistSeenNo" className={textStyle}>No</label>
        </div>
      </div>

      <div className="mb-4">
  <label className={textStyle}htmlFor="specialistType">
    Specialist Type:
  </label>
  <input
        className={inputSelectorStyle('specialistType')}
        id="specialistType"
    type="text"
    placeholder="Specialist Type"
    name="specialistType"
    value={formData.specialistType}
    onChange={handleChange}
  />
</div>

<div className="mb-4">
  <label className={textStyle}>
    Have you been admitted to a hospital?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="admittedToHospitalYes"
      name="admittedToHospital"
      value="true"
      checked={formData.admittedToHospital === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="admittedToHospitalYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="admittedToHospitalNo"
      name="admittedToHospital"
      value="false"
      checked={formData.admittedToHospital === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="admittedToHospitalNo" className={textStyle}>No</label>
  </div>
</div>

<div className="mb-4">
  <label className={textStyle}>
    Have you had to go to a hospital Emergency for treatment?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="emergencyVisitYes"
      name="emergencyVisit"
      value="true"
      checked={formData.emergencyVisit === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="emergencyVisitYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="emergencyVisitNo"
      name="emergencyVisit"
      value="false"
      checked={formData.emergencyVisit === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="emergencyVisitNo" className={textStyle}>No</label>
  </div>
</div>

{/* Activity */}
<div className="mb-4">
  <label className={textStyle}>
    Have you walked?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="walkingYes"
      name="walking"
      value="true"
      checked={formData.walking === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="walkingYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="walkingNo"
      name="walking"
      value="false"
      checked={formData.walking === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="walkingNo" className={textStyle}>No</label>
  </div>
</div>

<div className="mb-4">
  <label className={textStyle}>
    Are you gardening?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="gardeningYes"
      name="gardening"
      value="true"
      checked={formData.gardening === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="gardeningYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="gardeningNo"
      name="gardening"
      value="false"
      checked={formData.gardening === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="gardeningNo" className={textStyle}>No</label>
  </div>
</div>

<div className="mb-4">
  <label className={textStyle}>
    Are you doing houseowrk?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="houseworkYes"
      name="housework"
      value="true"
      checked={formData.housework === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="houseworkYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="houseworkNo"
      name="housework"
      value="false"
      checked={formData.housework === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="houseworkNo" className={textStyle}>No</label>
  </div>
</div>
<div className="mb-4">
  <label className={textStyle}>
    Are you doing any other forms of exercise?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="otherActivityYes"
      name="otherActivity"
      value="true"
      checked={formData.otherActivity === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="otherActivityYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="otherActivityNo"
      name="otherActivity"
      value="false"
      checked={formData.otherActivity === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="otherActivityNo" className={textStyle}>No</label>
  </div>
</div>



{/* Structured Exercise Program */}
<div className="mb-4">
  <label className={textStyle}>
    Are you participating in a structured exercise program?
  </label>
  <div className="flex items-center">
    <input
      type="checkbox"
      id="structuredExerciseYes"
      name="structuredExerciseProgram"
      value="true"
      checked={formData.structuredExerciseProgram === true}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="structuredExerciseYes" className={textStyle}>
      Yes
    </label>
    <input
      type="checkbox"
      id="structuredExerciseNo"
      name="structuredExerciseProgram"
      value="false"
      checked={formData.structuredExerciseProgram === false}
      onChange={handleChange}
      className={inputCheckboxStyle}
    />
    <label htmlFor="structuredExerciseNo" className={textStyle}>No</label>
  </div>
</div>

{/* Pillows for Sleep */}
<div className="mb-4">
  <label className={textStyle} htmlFor="pillowsForSleep">
    How many pillows do you need to use to sleep?
  </label>
  <select
                      className={inputSelectorStyle('pillowsForSleep')}
                      id="pillowsForSleep"
    name="pillowsForSleep"
    value={formData.pillowsForSleep}
    onChange={handleChange}
  >
    <option value="none">None</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="6">6</option>
    <option value="7">7</option>
  </select>
</div>

<div className="mb-4">
  <label className={textStyle} htmlFor="pillowsForSleep">
  Has there been a change in your energy level since your last
appointment?   </label>
  <select
                      className={inputSelectorStyle('energyLevelChange')}
                      id="energyLevelChange"
    name="energyLevelChange"
    value={formData.energyLevelChange}
    onChange={handleChange}
  >
    <option value="Yes">Yes</option>
    <option value="Better">Better</option>
    <option value="Worst">Worst</option>
    <option value="No">No</option>
  </select>
</div>

{/* Problems */}
<div className="mb-4">
<label className={textStyle}>
  Over the last two weeks, how often have you been bothered by any of the following problems?
</label>
<div className="mb-2">
  <label className={textStyle}>
    <input
      type="radio"
      className={inputRadioStyle}
      name="problems"
      value="Anhedonia"
      checked={formData.problems === 'Anhedonia'}
      onChange={handleChange}
    />
    Little interest or pleasure in doing things
  </label>
</div>
<div className="mb-2">
  <label className={textStyle}>
    <input
      type="radio"
      className={inputRadioStyle}
      name="problems"
      value="Depressed mood"
      checked={formData.problems === 'Depressed mood'}
      onChange={handleChange}
    />
    Feeling down, depressed, or hopeless
  </label>
</div>
<div className="mb-2">
  <label className={textStyle}>
    <input
      type="radio"
      className={inputRadioStyle}
      name="problems"
      value="Feeling anxious"
      checked={formData.problems === 'Feeling anxious'}
      onChange={handleChange}
    />
    Feeling anxious, nervous, or on edge
  </label>
</div>
<div className="mb-2">
  <label className={textStyle}>
    <input
      type="radio"
      className={inputRadioStyle}
      name="problems"
      value="Worrying"
      checked={formData.problems === 'Worrying'}
      onChange={handleChange}
    />
    Not being able to stop or control worrying
  </label>
  </div>
</div>

      <div className="flex items-center justify-between">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
);
};

export default HeartFailureQuestionnaire;
