import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import sanitizeHtml from 'sanitize-html';

const MarkdownRenderer = ({ markdownText }) => {
  // Define a list of allowed HTML tags and attributes for sanitization
  const allowedHtmlTags = sanitizeHtml.defaults.allowedTags;
  const allowedHtmlAttributes = sanitizeHtml.defaults.allowedAttributes;

  // Sanitize the HTML content
  const sanitizedHtml = sanitizeHtml(markdownText, {
    allowedTags: allowedHtmlTags,
    allowedAttributes: allowedHtmlAttributes,
  });

  return (
    <div className="prose prose-sm dark:prose-invert">
      <ReactMarkdown remarkPlugins={[gfm]} components={{ p: 'div' }}>
        {sanitizedHtml}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;
