import { useEffect, useState } from 'react';
import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useTemplate = () => {
  // Define your state variables here
  const [templateData, setTemplateData] = useState({});
  const [loading, setLoading] = useState(true);

  // Simulate fetching data from an API or performing some asynchronous operation
  useEffect(() => {
    // You can perform any asynchronous operation here (e.g., fetching data from an API)
    // For demonstration purposes, let's simulate a network request delay
    const fetchData = async () => {


        try {
            const response = await axios.get(`${REACT_APP_API_BASE_URL}/templates`, {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                // Add any other necessary headers
              }
    
            });
        // Organize fetched data by templateType
        const organizedData = {};
        response.data.forEach(template => {
          const { templateType, templateContent } = template;
          organizedData[templateType] = templateContent;
        });

        // Update the state with the organized data
        setTemplateData(organizedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        // Handle error scenarios here
      }
    };

    fetchData();

    // Cleanup function (optional)
    return () => {
      // Perform any cleanup tasks here (if necessary)
    };
  }, []); // This empty dependency array ensures that the effect runs only once, similar to componentDidMount

  // Return the state and any other functions or values needed by components
  return { templateData, loading };
};

export default useTemplate;
