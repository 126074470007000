import React, { useParams } from 'react-router-dom';
import BreadcrumbPatient from '../../components/BreadcrumbPatient';
import Tabs from '../../components/Tabs';
import CardVisitList from '../../components/cards/CardListVisit';
import CommsLog from '../../components/communication/CommsLog';
import CheckList from '../../components/CheckList';
import useVisitAndPatientData from '../../hooks/useVisitAndPatientData';
import CardAddToList from '../../components/cards/CardAddToList';

const Patient = () => {
  const { uuid } = useParams();
  const { visitData, patientData, loading } = useVisitAndPatientData(uuid);
  // console.log("patient Data from hook:", patientData);

  if (loading) {
    return <div>Loading...</div>; // You can customize the loading indicator as needed
  }

  const patientId = visitData ? visitData.PatientId : null;
  // console.log("Patient Id in parent", patientId);

  return (
    <>
      <div className="flex justify-between pt-4">
        <div>
          {patientData && (
            <BreadcrumbPatient
              name={patientData.name}
              nric={patientData.nric}
              daStatus={patientData.daStatus}
              daName={patientData.daName}
              age={patientData.age}
              gender={patientData.gender}
            />
          )}
        </div>
        <div>
          {uuid && <Tabs uuid={uuid} />}
        </div>
      </div>
      <div className="mb-4 border-b-2 w-full border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
        {visitData && patientId && (
          <CheckList patientId={patientId} uuid={uuid} />
        )}
      </div>
      <div className="mb-4 flex gap-4">
  <div className="w-1/2 flex flex-col gap-4">
    <div className="border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
      {visitData && patientId && (
        <CardVisitList patientId={patientId} />
      )}
    </div>
    <div className="border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
      {visitData && patientId && (
        <CardAddToList patientId={patientId} />
      )}
    </div>
  </div>
  <div className="w-1/2 border-b-2 border-stroke bg-white rounded-sm p-6 dark:bg-boxdark flex-grow">
    {visitData && patientId && (
      <CommsLog patientId={patientId} />
    )}
  </div>
</div>

    </>
  );
};

export default Patient;
