import sanitizeHtml from 'sanitize-html';

const sanitizeAndReplace = (input) => {
    // Sanitize HTML to remove any potentially harmful tags
    const sanitizedInput = sanitizeHtml(input, {
        allowedTags: [],
        allowedAttributes: {}
    });

    // Replace special characters with their corresponding HTML entities
    const replacedInput = sanitizedInput.replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&le;/g, '≤')
    .replace(/&ge;/g, '≥');

    // console.log(replacedInput);

    return replacedInput;
};

export default sanitizeAndReplace;
