import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/Footer';
import logo from '../../images/logo/logo-colorful.png';
import { Spinner } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const requiredAsteriskStyle = "text-red-500 mx-1";
const inputSelectorStyle =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";


const PasswordCheck = () => {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [linkActive, setLinkActive] = useState('');
    const [patientId, setPatientId] = useState(null); // State to store PatientId
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    const [error, setError] = useState(false); // New error state
    const [errorMessage, setErrorMessage] = useState(''); // New error state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const visitResponse = await fetch(`${REACT_APP_API_BASE_URL}/patient/visit/uuid/${uuid}`);
                if (!visitResponse.ok) {
                    throw new Error('Failed to fetch visit data');
                }

                const visitData = await visitResponse.json();
                // console.log('Visit Data:', visitData);
                const visitId = visitData.id; // Extract VisitId here
             
                const extractedPatientId = visitData.PatientId;
                const extractedLinkActive = visitData.linkActive;

                setLinkActive(extractedLinkActive);
                setPatientId(extractedPatientId);

                // Check if linkActive is true
                if (visitData.linkActive) {
                    setLoading(false);
                } else {
                    // Navigate to inactive link if linkActive is false
                    // console.log('Link is not active. Navigate to inactive link page.');
                    navigate('/inactivelink');  // Use the proper route without the base URL
                }

            } catch (error) {
                console.error('Error fetching visit data:', error);
                setLoading(false); // Set loading to false in case of an error
            }
        };

        fetchData();
    }, [uuid, navigate]);


    const handlePasswordChange = (e) => {
        setPassword(e.target.value); // Update the password state as the user types
    };

    const checkPasswordValidity = async () => {
        try {
          // Convert the password to lowercase
          const lowercasePassword = password.toLowerCase();
      
          // console.log("patientId", patientId);
          const dataToSend = {
            PatientId: patientId,
            password: lowercasePassword, // Use the lowercase password in the API call
            uuid,
          };
      
          // console.log("Data to send", dataToSend);
      
          const response = await axios.post(
            `${REACT_APP_API_BASE_URL}/checkPassword`,
            dataToSend,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
      
          if (response.status === 200) {
            // Assuming the session token is returned in the response
            const token = response.data.token;
            
  // Store the session data in the session storage or manage it in the frontend
  sessionStorage.setItem('sessionToken',token);
  // console.log("SessionData Stored");
            // Redirect to the report page
            window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/report?u=${uuid}&p=${patientId}`;
          } else if (response.status === 401) {
            setError(true);
            setErrorMessage("Wrong password. Please try again."); // Set error state on 401 response status
          } else {
            setError(true);
            setErrorMessage("Error: Invalid response status"); // Set error state on other response statuses
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          setError(true);
          setErrorMessage("Wrong password. Please try again without any symbols.");
        }
      };
      
    
    const handleSave = async () => {
        setError(false); // Reset error state on Save button click
        
        // Check if patientId is available before calling checkPasswordValidity
        if (patientId !== null) {
            await checkPasswordValidity();
        } else {
            console.error("PatientId is null. Cannot proceed with password validation.");
        }
    };
    

    return (
        <section className="flex flex-col items-center justify-center h-screen bg-black dark:bg-gray-900">
            {loading ? (
                <div className="text-center">
                    <div role="status">
                        <Spinner className="h-12 w-12" />
                    </div>
                </div>
            ) : (

                <div className="flex flex-col items-center justify-center px-6 py-2 mx-auto lg:py-2">
                    <a href="https://www.milkiway.ai/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img src={logo} alt="logo" className="h-34 w-auto" />
                    </a>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Open Your Record
                            </h1>
                            <div className="flex items-center text-left p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 pb-4" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <div>
                                    Please enter your fullname and NRIC/FIN in lowercase without spaces. Ex: marytans1234567a
                                </div>
                            </div>
                            {error && errorMessage && ( // Display error message if error state is not null
                                <div className="flex items-center text-left p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <div>
                                        {errorMessage}
                                    </div>
                                </div>
                            )}
                            <div className="w-full col-span-1 text-left">
                                <label className={textStyle}>Password</label>
                                <span className={requiredAsteriskStyle}>*</span>
                                <input
                                    type="text"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    className={`${inputSelectorStyle
                                        } ${error ? 'border-red-500' : 'border-gray-300'} focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 rounded-lg dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500`}
                                    required
                                />
                            </div>
                            <button
                                className="w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                onClick={handleSave}
                            >
                                Open
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </section>
    );
};

export default PasswordCheck;