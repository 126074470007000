import React from "react";

const Footer = () => {
  return (
    <div className="text-center">
      <div className="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center justify-between md:justify-between md:p-4 sm:flex sm:text-center sm:items-center sm:justify-center dark:bg-gray-800 dark:border-gray-600">
        <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://milkiway.ai/" className="hover:underline">Milkiway™</a>. All Rights Reserved.</span>
        <ul className="hidden md:flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a href="https://milkiway.ai/" className="text-xs mr-4 hover:underline md:mr-6">About</a>
          </li>
          <li>
        <a href="https://doc.milkiway.ai/" className="text-xs mr-4 hover:underline md:mr-6">Docs</a>
        </li>
          <li>
            <a href="https://milkiway.ai/privacy/" className="text-xs mr-4 hover:underline md:mr-6">Privacy Policy</a>
          </li>
          <li>
            <a href="https://milkiway.ai/terms/" className="text-xs mr-4 hover:underline md:mr-6">Terms</a>
          </li>
          <li>
            <a href="mailto:hello@milkiway.ai" className="text-xs hover:underline">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
