import React, { useState, useEffect } from 'react';
import PostComment from './PostComment';
import CommentList from './CommentList';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

interface CommsLogProps {
  patientId: number;
}

const CommsLog: React.FC<CommsLogProps> = ({ patientId }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [refreshFlag, setRefreshFlag] = useState(false);

  const refreshComments = () => {
    // Toggle the refresh flag to trigger a re-render of CommentLists
    setRefreshFlag((prevFlag) => !prevFlag);
  };

  const fetchComments = () => {
    // Fetch comments and update the comments state
    fetch(`${REACT_APP_API_BASE_URL}/comments/${patientId}`, {
      credentials: 'include' // Include credentials (cookies) in the request
    })
    .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch comments');
        }
      })
      .then((commentsData: Comment[]) => {
        // console.log('Fetched comments:', commentsData);
        setComments(commentsData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    // Fetch comments when the component mounts
    fetchComments();
  }, [patientId]);

  return (
    <div className="py-2">
      <h3 className="text-xl font-semibold text-black dark:text-white pb-4">
        Communication Log
      </h3>
      <div>
        <PostComment
          onCommentSubmit={refreshComments} // Pass the callback function
          patientId={patientId}
        />
      </div>
      <div className="pt-4">
      <CommentList patientId={patientId} key={refreshFlag ? 'refresh' : 'no-refresh'} />
      </div>
    </div>
  );
};

export default CommsLog;
