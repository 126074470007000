import { Typography } from '@material-tailwind/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useVisitAndPatientData from '../../hooks/useVisitAndPatientData';
import useFormData from '../../hooks/useFormData';
import calculateAge from '../../function/calculateAge';

const PrintView = () => {
    const { uuid } = useParams();
    // console.log("uuid", uuid);  

    const { visitData, patientData, loading } = useVisitAndPatientData(uuid);
    const { pastMedicalHistory, lifestyleHistory, familyHistory, physicalExam, labs, recommendation, colonoscopy, audiogram, colorVision, tonometry } = useFormData(uuid);



    useEffect(() => {

    }, [patientData, visitData, loading, pastMedicalHistory, lifestyleHistory, familyHistory, physicalExam, labs, recommendation, colonoscopy, audiogram, colorVision, tonometry]);
    // console.log("pastMedicalHistory", pastMedicalHistory)
    if (loading) {
        // Render a loading indicator or message
        return <div>Loading...</div>;
    }

    // Convert birthdate to local timestamp
    const birthDateTimestamp = patientData ? new Date(patientData.birthDate).toLocaleDateString() : '-';
    const age = calculateAge(birthDateTimestamp);

    const drugAllergyStatusText = patientData ? (patientData.daStatus === "NKDA" ? "No" : "Yes") : '-';


    return (
        <div className="bg-gray flex justify-center h-full">
            <div className="print-view-container mx-10 my-10 px-10 py-10 w-full bg-white p-4 space-y-8">
                <div className="header mb-8">
                    <Typography variant="h5" color="black">Health Screening Report</Typography>
                    <p className="inline-block pr-2 text-black font-semibold">Date:</p>
                    <span className="inline-block">{new Date().toLocaleDateString()}</span>
                    <div className="flex-1 w-60 pt-4">
                        <Typography variant="h3" color="black">Personal Details</Typography>
                        <div className="flex flex-row gap-4">
                            <div className='sm:w-60 lg:w-full'>
                                <table className="border-collapse border border-black text-black text-sm w-full">
                                    <tbody>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Name</strong></td>
                                            <td className="border border-black p-2">{patientData ? patientData.name : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black p-2"><strong>NRIC</strong></td>
                                            <td className="border border-black p-2">{patientData ? patientData.nric : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Age</strong></td>
                                            <td className="border border-black p-2">{age}</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Birthdate</strong></td>
                                            <td className="border border-black p-2">{birthDateTimestamp}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='sm:w-full  lg:w-full'>
                                <table className="border-collapse border border-black text-black text-sm w-full">
                                    <tbody>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Gender</strong></td>
                                            <td className="border border-black p-2">{patientData ? patientData.gender : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Race</strong></td>
                                            <td className="border border-black p-2">{patientData ? patientData.race : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black p-2"><strong>Drug Allergy</strong></td>
                                            <td className="border border-black p-2">{drugAllergyStatusText}</td>
                                        </tr>
                                        {patientData && patientData.daName && (
                                            <tr>
                                                <td className="border border-black p-2"><strong>Medication Alerts</strong></td>
                                                <td className="border border-black p-2">{patientData ? patientData.daName : '-'}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Recommendation */}
                {recommendation && (
                    <div className="flex">
                        <div className="flex-2">
                            <Typography variant="h3" color="black">Recommendation</Typography>
                            <table className="border-collapse border border-black text-black text-sm">
                                <tbody>
                                    {recommendation && recommendation.map((recommendation, index) => (
                                        <tr key={index}>
                                            <td className="border border-black p-2 w-1/4"><strong>{recommendation.recTitle}</strong></td>
                                            <td className="border border-black p-2 w-3/4" dangerouslySetInnerHTML={{ __html: formatRecommendation(recommendation.recContent) }}></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Past medical History, Family History, Lifestyle History */}
                {pastMedicalHistory && lifestyleHistory && familyHistory && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Medical History</Typography>
                            <table className="border-collapse border border-black text-black text-sm w-full">
                                <tbody>
                                    <tr>
                                        <td className="border border-black p-2 w-1/4"><strong>Past Medical History</strong></td>
                                        <td className="border border-black p-2 w-3/4">
                                            {pastMedicalHistory && pastMedicalHistory.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-black p-2"><strong>Family History</strong></td>
                                        <td className="border border-black p-2">
                                            {familyHistory.map((relationshipItem, index) => (
                                                <p key={index}>
                                                    {`${relationshipItem.familyMember}: ${relationshipItem.diseases}`}
                                                    <br />
                                                </p>
                                            ))}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-black p-2"><strong>Lifestyle History</strong></td>
                                        <td className="border border-black p-2">
                                            {lifestyleHistory && lifestyleHistory.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {/* Physical Exam */}
                {physicalExam && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Physical Exam Findings</Typography>
                            <table className="border-collapse border border-black text-black text-sm w-full">
                                <tbody>
                                    {physicalExam && physicalExam.map((physicalExam, index) => (
                                        <tr key={index}>
                                            <td className="border border-black p-2 w-1/4"><strong>{physicalExam.category}</strong></td>
                                            <td className="border border-black p-2 w-2/4">
                                                {physicalExam.description}
                                            </td>
                                            <td className="border border-black p-2 w-1/4">
                                                {physicalExam.status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Labs */}
                {labs && labs.some(lab => lab.parameters.some(param => param.value !== '')) && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Significant Labs Findings</Typography>
                            <table className="border-collapse border border-black text-black text-sm w-full">
                                <tbody>
                                    {labs.map((lab, labIndex) => (
                                        lab.parameters.some(param => param.value !== '') && (
                                            <tr key={labIndex} className="border-b border-black">
                                                <td className="p-2"><strong>{lab.labTest}</strong></td>
                                                <td className="p-2">{lab.testDate}</td>
                                                <td>
                                                    <ul>
                                                        {lab.parameters.map((param, paramIndex) => (
                                                            param.value !== '' && (
                                                                <li key={paramIndex}>
                                                                    <strong>{param.parameter}:</strong> {param.value} [Reference value {param.reference} {param.unit}]
                                                                </li>
                                                            )
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* colorVision */}

                {colorVision && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Ishihara Color Vision Test</Typography>
                            {/* Rendering report */}
                            <table className="text-black text-sm border border-black px-2 w-1/2">
                                <thead>
                                    <tr>
                                        <th className="w-1/5 px-2 text-start border-b border-r border-black"></th>
                                        <th className="w-2/5 px-2 text-start border-b border-r border-black">Left Eye</th>
                                        <th className="w-2/5 px-2 text-start border-b border-black">Right Eye</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-2 border-b border-r border-black">Score</td>
                                        <td className="px-2 border-b border-r border-black">{colorVision.Left_Eye_Color_Vision_Score}</td>
                                        <td className="px-2 border-b border-black">{colorVision.Right_Eye_Color_Vision_Score}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 border-r border-black">Assessment</td>
                                        <td className="px-2 border-r border-black">{colorVision.Left_Eye_Asessment}</td>
                                        <td className="px-2">{colorVision.Right_Eye_Assessment}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}


                {/* Tonometry */}
                {tonometry && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Tonometry</Typography>
                            {/* Rendering report */}
                            <table className="text-black text-sm border border-black px-2 w-1/2">
                                <thead>
                                    <tr>
                                        <th className="w-1/5 px-2 text-start border-b border-r border-black"></th>
                                        <th className="w-2/5 px-2 text-start border-b border-r border-black">Left Eye</th>
                                        <th className="w-2/5 px-2 text-start border-b border-black">Right Eye</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-2 border-b border-r border-black">Pressure (mmHg)</td>
                                        <td className="px-2 border-b border-r border-black">{tonometry.Left_Eye_Tonometry_Score}</td>
                                        <td className="px-2 border-b border-black">{tonometry.Right_Eye_Tonometry_Score}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 border-r border-black">Assessment</td>
                                        <td className="px-2 border-r border-black">{tonometry.Left_Eye_Asessment}</td>
                                        <td className="px-2">{tonometry.Right_Eye_Assessment}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Audiogram */}
                {audiogram && (
                    <div className="w-full">
                        <div className="">
                            <Typography variant="h3" color="black">Audiogram Findings</Typography>
                            {/* Rendering report */}
                            <table className="text-black text-sm border border-black px-2">
                                <thead>
                                    <tr>
                                        <th className="border-b border-r border-black px-2"></th>
                                        <th className="border-b border-r border-black px-2">Left Ear (dB)</th>
                                        <th className="border-b border-black px-2">Right Ear (dB)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-b border-r border-black px-2 text-black text-sm font-medium">Frequency (Hz)</td>
                                        <td className="border-b border-r border-black px-2">{audiogram.leftEarAudiometry.interpretation}</td>
                                        <td className="border-b border-black px-2">{audiogram.rightEarAudiometry.interpretation}</td>
                                    </tr>
                                    {/* Render each frequency row */}
                      {/* Render each frequency row */}
{Object.keys(audiogram.leftEarAudiometry.values).map((frequency) => (
    <tr key={frequency}>
        <td className="border-r border-black px-2">{frequency}</td>
        <td className="border-r border-black px-2">{audiogram.leftEarAudiometry.values[frequency]}</td>
        <td className="px-2">{audiogram.rightEarAudiometry.values[frequency]}</td>
    </tr>
))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )}


                {/* Colonoscopy */}
                {colonoscopy && (
                    <div className="w-full ">
                        <div className="">
                            <Typography variant="h3" color="black">Colonoscopy Findings</Typography>
                            {/* Rendering report */}
                            <div className="text-black text-sm border border-black p-2">
                                {colonoscopy && colonoscopy.report.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}     </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default PrintView;

const formatRecommendation = (content) => {
    return content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n\n/g, '<br><br>');
};