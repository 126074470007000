import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../images/logo/logo-colorful.png';
import Footer from '../../components/Footer';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const requiredAsteriskStyle = "text-red-500 mx-1";
const inputSelectorStyle = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";

const ClinicSelection = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [clinicId, setClinicId] = useState('');
    const [clinicOptions, setClinicOptions] = useState([]);
    const [userClinics, setUserClinics] = useState([]);
    const navigate = useNavigate();

    const fetchUserDetailsAndClinics = async () => {
        try {
            const userClinicResponse = await axios.get(`${REACT_APP_API_BASE_URL}/associate`, {
              withCredentials: true, // Send cookies with the request
            });

            const userClinicsData = userClinicResponse.data;
            // console.log("Fetched clinic data", userClinicsData);

            const clinics = userClinicsData.map(userClinic => ({
                label: userClinic.clinicId,
                value: userClinic.clinicId
                // Add other fields if needed
            }));

            setClinicOptions(clinics);
            setUserClinics(userClinicsData);
        } catch (error) {
            console.error('Error fetching user details and clinics:', error);
        }
    };

    useEffect(() => {
        fetchUserDetailsAndClinics();
    }, []);

    const handleFormSubmit = async () => {
        try {
          // Find the selected clinic based on clinicId
          const selectedClinic = userClinics.find(clinic => clinic.clinicId === clinicId);
      
          if (selectedClinic) {
            const dataToSend = {
              clinicId: selectedClinic.id, // Use the ID of the selected clinic
            };
            // console.log("Data to Send", dataToSend);
      
            const config = {
              method: 'post',
              url: `${REACT_APP_API_BASE_URL}/session`,
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
              data: dataToSend,
            };
      
            const response = await axios(config);
      
            if (response.status === 200) {
              // Redirect to the specific clinic page upon success
              // console.log("clinicId", selectedClinic.id);
              // window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/list`;
             navigate('/list');

            } else {
              console.error('Unexpected response:', response);
              // Handle unexpected response codes
            }
          } else {
            console.error('Selected clinic not found.');
            // Handle the case where the selected clinic is not found
          }
        } catch (error) {
          console.error('Error setting cookie:', error);
          // Handle the error setting clinic cookie
        }
      };
      
    return (
        <section className="h-screen bg-black dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-2 mx-auto lg:py-2">
                <a href="https://www.milkiway.ai/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img src={logo} alt="logo" className="h-34 w-auto" />
                </a>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Almost there!
                        </h1>
                        <div className="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <div className="text-left">
                                Choose your clinic location.
                            </div>
                        </div>
                        <div className="w-full text-left">
                            <label className={textStyle}>Clinic Id<span className={requiredAsteriskStyle}>*</span></label>
                            <select
                                name="clinicId"
                                value={clinicId}
                                onChange={(e) => setClinicId(e.target.value)}
                                className={inputSelectorStyle}
                            >
                                <option value="">Select Clinic</option>
                                {clinicOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errorMessage && (
                            <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="font-medium pr-2">Error!</span> {errorMessage}
                            </div>
                        )}
                        {/*  <div className="w-full text-left">
                            <label className={textStyle}>Picture</label>
                            <span className={requiredAsteriskStyle}>*</span>
                            <div className="flex items-center justify-center w-full">
                                <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="dropzone-file" type="file" className="hidden" onChange={handleFileUpload} />
                                </label>
                            </div>
                        </div> */}
                        <button
                            onClick={handleFormSubmit}
                            className="w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default ClinicSelection;