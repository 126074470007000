import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchBarTablePatientList from '../components/SearchBarTablePatientList';
import { Plus, ArrowUp, ArrowDown, ChevronDown, Filter } from 'lucide-react';
import moment from 'moment';
import ModalPatientRegistration from '../components/modal/ModalPatientRegistration';
import ModalAddVisit from '../components/modal/ModalAddVisit';
import DateRangeFilter from '../components/DateRangeFilter';
import validateForm from '../function/formValidator';
import formValidationConfig from '../config/formValidationConfig.json';
import convertUTCToSGTimestamp from '../function/convertUTCToSGTimestamp';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface TablePatientList {
  patientId: number;
}

interface RowData {
  id: number;
  patientId: number;
  name: string;
  nric: string;
  age: number;
  birthDate: Date;
  gender: string;
  race: string;
  registrationDate: Date;
  healthScreeningPackage: string;
  uuid: string;
  memo: string;
}

const itemsPerPage = 50; // Adjust the number of items per page as needed
const inputStyles =
  'ml-2 mr-2 text-sm h-12 w-80 rounded-lg border-[1px] border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primaryColor-500 active:border-primaryColor-500 disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primaryColor-500';

const TablePatientList: React.FC = () => {
  const { clinicId } = useParams<{ clinicId: string }>();
  const [data, setData] = useState<RowData[]>([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<RowData[]>([]);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [paginatedData, setPaginatedData] = useState<RowData[]>([]);
  const [totalEnroled, setTotalEnroled] = useState<number>(0);
  const [numberLoaded, setNumberLoaded] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registrationUuid, setRegistrationUuid] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<number | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  // Define a state to store memo values with patient IDs as keys
  const [memoValues, setMemoValues] = useState<Record<number, string>>({});
  const [isAddVisitOpen, setIsAddVisitOpen] = useState(false); // State to control modal visibility
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedNRIC, setSelectedNRIC] = useState(null);
  const [userId, setUserId] = useState('');
  const [formErrorMessages, setFormErrorMessages] = useState({
    memo: undefined,
  });
  const [errorBoxHighlight, setErrorBoxHighlight] = useState({
    memo: false,
  });

  const fetchData = async () => {
    try {
      const today = new Date();

      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/patients/list`,
        {
          withCredentials: true, // Include credentials (cookies) in the request
        },
      );

      const enrolledData = response.data;

      const promises = enrolledData.map(async (item) => {
        const birthDate = convertUTCToSGTimestamp(item.birthDate);
        const age = Math.floor(
          (today.getTime() - new Date(birthDate).getTime()) /
            (365.25 * 24 * 60 * 60 * 1000),
        );

        const patientData = {
          id: item?.id || 0,
          patientId: item.id,
          name: item.name,
          nric: item.nric,
          birthDate: birthDate,
          gender: item.gender,
          age,
          memo: item.memo,
        };
        // console.log("item.id", item.id);

        const secondResponse = await axios.get(
          `${REACT_APP_API_BASE_URL}/visit/latest/${item.id}`,
          {
            withCredentials: true,
          },
        );

        const additionalData = secondResponse.data;
        // console.log('Additional Data:', additionalData);

        const { visitDate, ...restAdditionalData } = additionalData;
        const registrationDate = new Date(additionalData.visitDate);

        const combinedData = {
          ...patientData,
          ...restAdditionalData,
          registrationDate,
        };
       // console.log("combinedData", combinedData);

        return combinedData;

      });


      const parsedData = await Promise.all(promises);

      setData(parsedData);
      setFilteredData(parsedData);
      setTotalEnroled(parsedData.length);
      setNumberLoaded(Math.min(parsedData.length, itemsPerPage));
      setPaginatedData(parsedData.slice(0, itemsPerPage));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    // Check if there is a saved filter in localStorage
    const savedMinDate = localStorage.getItem('minDate');
    const savedMaxDate = localStorage.getItem('maxDate');

    if (savedMinDate && savedMaxDate) {
      const minDateObj = new Date(savedMinDate);
      const maxDateObj = new Date(savedMaxDate);
      applyDateFilter(minDateObj, maxDateObj); // Apply the saved filter
    }
  }, []); // Run this effect once when the component mounts

  
  // Function to handle memo value change for a specific patient
  const handleMemoChange = (patientId: number, memo: string) => {
    validateForm({ memo }, formValidationConfig, (errors) => {
      setFormErrorMessages((prevErrors) => ({
        ...prevErrors,
        [patientId]: { ...prevErrors[patientId], memo: errors.memo },
      }));
    });

    setMemoValues((prevMemoValues) => ({
      ...prevMemoValues,
      [patientId]: memo,
    }));
  };

  // Function to handle opening the modal
  const openAddVisitModal = () => {
    setIsAddVisitOpen(true);
  };
  // Function to handle closing the modal
  const closeAddVisitModal = () => {
    setIsAddVisitOpen(false);
  };

  const handleEnterKeyPress = (e, patientId) => {
    if (e.key === 'Enter') {
      // Prevent the default behavior (e.g., form submission)
      e.preventDefault();

      // Post the memo to the backend here
      const memo = memoValues[patientId] || '';
      // console.log("Memo", memo);
      if (memo) {
       // console.log(`Posting memo for patient ID ${patientId} with memo: ${memo}`);
        postMemoToBackend(patientId, memo);
      }
    }
  };

  const postMemoToBackend = async (patientId, memo) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/patients/memo/${patientId}`, // Include patientId in the URL
        { memo }, // Send memo data in the request body
        {
          withCredentials: true,
        },
      );

      // Handle the response as needed
      // console.log("Memo posted:", response);
    } catch (error) {
      // console.error("Error posting memo:", error);
    }
  };


  const loadNextBatch = () => {
    if (tableRef.current) {
      const currentRowCount = tableRef.current.tBodies[0].rows.length;
      const nextBatch = data.slice(
        currentRowCount,
        currentRowCount + itemsPerPage,
      );
      setPaginatedData((prevData) => [...prevData, ...nextBatch]);
      setNumberLoaded(Math.min(data.length, currentRowCount + itemsPerPage));
    }
  };

  // Callback function for handling search
  const handleSearch = (searchQuery: string) => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    // If search query is empty, display the entire dataset
    if (searchQuery.trim() === '') {
      setFilteredData(data);
      setPaginatedData(data.slice(0, itemsPerPage)); // Reset paginatedData
    } else {
      const filteredItems = data.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseQuery) ||
          item.nric.toLowerCase().includes(lowerCaseQuery),
      );

      setFilteredData(filteredItems);
      setPaginatedData(filteredItems.slice(0, itemsPerPage)); // Update paginatedData with filtered data
    }
  };

  // Function to handle table row click
  const handleTableRowClick = (rowData: RowData) => {
    navigate(`/patient/${rowData.uuid}`);
  };

  const SortIndicator: React.FC<{ order: 'asc' | 'desc' }> = ({ order }) => (
    <span className="ml-2 inline-block">
      {order === 'asc' ? (
        <ArrowUp className="w-4 h-4" />
      ) : (
        <ArrowDown className="w-4 h-4" />
      )}
    </span>
  );

  const handleAddVisit = (e, patientId, name, nric) => {
    e.stopPropagation(); // Prevent the click event from propagating to parent elements
    openAddVisitModal(); // Open the add visit modal
    setSelectedPatientId(patientId);
    setSelectedName(name);
    setSelectedNRIC(nric);
  };

  // Open registration modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close registration modal
  const closeModal = () => {
    setIsModalOpen(false);
    handleRefresh(); // Reload the parent component
  };

  // Handle form submission in the modal
  const handleRefresh = () => {
    // Update the state variable to trigger a re-render
    setRefresh((prevRefresh) => (prevRefresh !== null ? prevRefresh + 1 : 1));
  };

  const handleRegistration = (uuid: string) => {
    setIsModalOpen(false);
    setRegistrationUuid(uuid);
  };

  const handleRegistrationAndFetchData = async (uuid) => {
    await handleRegistration(uuid); // Register the patient
    await fetchData(); // Fetch data again
  };

  const handleSort = (column: string) => {
    // Toggle the sorting order if clicking the same column
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Reset sorting order if clicking a different column
      setSortedColumn(column);
      setSortOrder('asc');
    }

    // Sort the currently rendered data based on the current sorting criteria (sortedColumn and sortOrder)
    const sortedData = [...paginatedData].sort((a, b) => {
      const aValue = a[column as keyof RowData];
      const bValue = b[column as keyof RowData];

      if (sortOrder === 'asc') {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          // Sort strings in ascending order
          return aValue.localeCompare(bValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          // Sort numbers in ascending order
          return aValue - bValue;
        } else if (aValue instanceof Date && bValue instanceof Date) {
          // Sort dates in ascending order
          return aValue.getTime() - bValue.getTime();
        }
      } else {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          // Sort strings in descending order
          return bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          // Sort numbers in descending order
          return bValue - aValue;
        } else if (aValue instanceof Date && bValue instanceof Date) {
          // Sort dates in descending order
          return bValue.getTime() - aValue.getTime();
        }
      }
      return 0; // If no column is selected, maintain the current order
    });

    // Update the paginated data with the sorted data
    setPaginatedData(sortedData);
  };

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const applyDateFilter = (minDate, maxDate) => {
    if (minDate === null || maxDate === null) {
      // Clear the filter from localStorage when reset
      localStorage.removeItem('minDate');
      localStorage.removeItem('maxDate');
  
      // Reset the filter to display all data
      setFilteredData(data); // Show all data
      setPaginatedData(data.slice(0, itemsPerPage)); // Reset paginated data
      setNumberLoaded(itemsPerPage); // Reset number of items loaded
    } else {
      const minDateObj = new Date(minDate);
      const maxDateObj = new Date(maxDate);
      maxDateObj.setHours(23, 59, 59, 999); // Set it to the end of the day
  
      // Filter the data based on the date range
      const filteredData = data.filter((item) => {
        const registrationDate = new Date(item.registrationDate);
        return registrationDate >= minDateObj && registrationDate <= maxDateObj;
      });
  
      // Update the filtered data
      setFilteredData(filteredData);
      setPaginatedData(filteredData.slice(0, itemsPerPage)); // Paginate the filtered data
      setNumberLoaded(itemsPerPage); // Reset to the first page
  
      // Save the filter to localStorage
      localStorage.setItem('minDate', minDate);
      localStorage.setItem('maxDate', maxDate);
    }
  };
  

  return (
    <div className="relative overflow-x-auto bg-white dark:bg-gray-800 py-6 px-6 sm:rounded-lg">
      <div className="flex items-center justify-between pb-2">
        <SearchBarTablePatientList onSearch={handleSearch} />
        <div>
          <button
            className="inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            type="button"
            onClick={openModal}
          >
            <Plus className="mr-2 w-4 h-4" />
            New Patient
          </button>
          <ModalPatientRegistration
            userId={userId}
            clinicId={clinicId}
            isOpen={isModalOpen}
            onClose={closeModal}
            onRegister={handleRegistrationAndFetchData}
          />
          <button
            className="inline-flex items-center text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            type="button"
            onClick={toggleDropdown}
          >
            <Filter className="mr-2 w-4 h-4" />
            Filter
            <ChevronDown className="ml-2 w-4 h-4" />
          </button>

          {showDropdown && (
            <div className="absolute right-6 mt-1 w-90 bg-white border border-gray-300 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-500 z-10">
              {/* Dropdown content */}
              <DateRangeFilter applyDateFilter={applyDateFilter} />
            </div>
          )}
        </div>
      </div>
      <table className="mt-4 w-full text-sm text-left text-gray-500 dark:text-gray-400 dark:bg-gray-800">
        <thead className="text-xs items-start text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {/* <th scope="col" className="p-4">
              <button onClick={() => handleSort('id')}>
                ID {sortedColumn === 'id' && <SortIndicator order={sortOrder} />}
              </button>
            </th>*/}
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('name')}>
                NAME{' '}
                {sortedColumn === 'name' && <SortIndicator order={sortOrder} />}
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('nric')}>
                NRIC{' '}
                {sortedColumn === 'nric' && <SortIndicator order={sortOrder} />}
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('age')}>
                AGE{' '}
                {sortedColumn === 'age' && <SortIndicator order={sortOrder} />}
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('gender')}>
                GENDER{' '}
                {sortedColumn === 'gender' && (
                  <SortIndicator order={sortOrder} />
                )}
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('registrationDate')}>
                LAST VISIT DATE{' '}
                {sortedColumn === 'registrationDate' && (
                  <SortIndicator order={sortOrder} />
                )}
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              <button onClick={() => handleSort('healthScreeningPackage')}>
                REMARK
              </button>
            </th>
            <th scope="col" className="px-6 py-3">
              ACTIONS
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr
              key={item.id}
              className="hover-bg-gray-100"
              onClick={() => handleTableRowClick(item)}
              style={{ cursor: 'pointer' }}
            >
              {/* <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-left">
                {item.patientId}
              </td> */}
              <td className="text-sm border-b font-medium text-black border-[#eee] px-6 py-3 dark-border-strokedark text-left">
                {item.name}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-left">
                {item.nric}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-justify">
                {item.age}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-justify">
                {item.gender}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-justify">
                {moment(item.registrationDate).format('DD-MM-YYYY')}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-justify">
                <input
                  type="text"
                  name="memo"
                  value={memoValues[item.patientId] || item.memo || ''} // Sanitize the value
                  className={inputStyles}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    handleMemoChange(item.patientId, e.target.value)
                  }
                  onKeyDown={(e) => handleEnterKeyPress(e, item.patientId)}
                />
                {formErrorMessages &&
                  formErrorMessages[item.patientId] &&
                  formErrorMessages[item.patientId].memo && (
                    <div className="py-2 text-red-500 text-xs">
                      {formErrorMessages[item.patientId].memo}
                    </div>
                  )}
              </td>
              <td className="text-sm border-b border-[#eee] px-6 py-3 dark-border-strokedark text-justify">
                <button
                  className="text-primaryColor-500 inline-flex items-center hover:text-white border border-primaryColor-500 hover:bg-primaryColor-500 focus:outline-none focus:ring-primaryColor-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primaryColor-300 dark:text-white  dark:hover:bg-primaryColor-600 dark:hover:text-white"
                  onClick={(e) =>
                    handleAddVisit(e, item.patientId, item.name, item.nric)
                  }
                >
                  Add a Visit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalAddVisit
        openAddVisitModal={isAddVisitOpen}
        closeAddVisitModal={closeAddVisitModal}
        patientId={selectedPatientId}
        name={selectedName}
        nric={selectedNRIC}
      />
      <div>
        {numberLoaded < totalEnroled && (
          <div className="text-center py-4">
            <button
              onClick={loadNextBatch}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Load more
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-grow pt-8 pb-2 justify-end">
        <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border:gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li className="w-full px-4 py-2 border-b border:gray-200 rounded-t-lg dark:border-gray-600">
            Total: {totalEnroled}
          </li>
          <li className="w-full px-4 py-2 border-b border:gray-200 dark:border-gray-600">
            Currently loaded: {numberLoaded}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TablePatientList;
