import React, { useState } from 'react';
import privateLinkHero from '../../images/hero/private-link.png';
import Footer from '../../components/Footer';
import Tooltip from '../../components/Tooltip';

const InactiveLink = () => {
    const [language, setLanguage] = useState('en');
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);

    const handleToggleLanguageMenu = () => {
        setShowLanguageMenu(!showLanguageMenu);
    };

    const handleToggleLanguage = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        setShowLanguageMenu(false);
    };

    const languages = [
        { code: 'en', name: 'English' },
        { code: 'zh', name: '中文' },
        { code: 'ms', name: 'Malay' },
        { code: 'ta', name: 'தமிழ்' },
        { code: 'es', name: 'Español' },
    ];

    const handleGoHome = () => {
        // Redirect to the desired URL (https://www.milkiway.ai/)
        window.location.href = 'https://www.milkiway.ai/';
    };


    const getLocalizedContent = (lang) => {
        switch (lang) {
            case 'en':
                return {
                    title: 'Oh no! This is a private link.',
                    message: "We're sorry, the accessed link is set as private. Please contact your healthcare provider to activate the link and access the content."
                };
            case 'zh':
                return {
                    title: '这是一个私人链接。',
                    message: '抱歉，访问的链接被设置为私密链接。请联系您的诊所查看内容。'
                };
            case 'ms':
                return {
                    title: 'Ini adalah pautan peribadi.',
                    message: 'Maaf, pautan yang diakses ditetapkan sebagai peribadi. Sila hubungi penyedia penjagaan kesihatan anda untuk mengaktifkan pautan dan mengakses kandungan.'
                };
            case 'ta':
                return {
                    title: 'இது ஒரு தனிப்பட்ட இணையம்.',
                    message: 'மன்னிக்கவும், அணுகப்பட்ட இணையத்திற்கு அணுகவும். உங்கள் மருத்துவ வழியாளரை தொடர்பு கொள்ளி இணையத்தை செயலாக்கி உள்ளத்தை அணுகவும்.'
                };
                case 'es':
                    return {
                        title: '¡Oh no! Este es un enlace privado.',
                        message: 'Lo sentimos, el enlace al que se accedió está configurado como privado. Por favor, contacta a tu proveedor de atención médica para activar el enlace y acceder al contenido.'
                    };
            default:
                return {
                    title: 'Oh no! This is a private link.',
                    message: "We're sorry, the accessed link is set as private. Please contact your healthcare provider to activate the link and access the content."
                };
        }
    };

    const { title, message } = getLocalizedContent(language);
    
    const getButtonText = (lang) => {
        switch (lang) {
            case 'en':
                return 'Go Home';
            case 'zh':
                return '回首页';
            case 'ms':
                return 'Kembali ke halaman utama';
            case 'ta':
                return 'முகப்புப் பக்கம்';
            case 'es':
                    return 'Volver a la página de inicio';
            default:
                return 'Go Home';
        }
    };

    const buttonText = getButtonText(language);
    return (
        <section className="flex flex-col items-center justify-center min-h-screen">
            <img
                src={privateLinkHero}
                className="object-cover w-full h-full fixed inset-0 z-0"
                alt="Private Link Hero"
            />
            <div className="bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 z-10">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
                    <div className="flex justify-end">
                        <div className="relative inline-block text-left">
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                                    onClick={handleToggleLanguageMenu}
                                >
                                    {languages.find((lang) => lang.code === language)?.name || 'Language'}
                                    <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                            {showLanguageMenu && (
                                <div
                                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  z-20"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="language-menu"
                                    tabIndex="-1"
                                >
                                    <div className="py-1" role="none">
                                        {languages.map((lang) => (
                                            <button
                                                key={lang.code}
                                                onClick={() => handleToggleLanguage(lang.code)}
                                                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                role="menuitem"
                                                tabIndex="-1"
                                            >
                                                {lang.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        {title}
                    </h1>
                    <div className="flex items-center p-4 mb-4 text-base text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <div className="text-left">
                            {message}
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center">
                <Tooltip text="Go Home">
                    <button
                        type="button"
                        onClick={handleGoHome}
                        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center me-2 mb-2 z-10"
                    >
                        {buttonText}
                    </button>
                </Tooltip>
            </div>        </div>
            </div>
            <Footer />
        </section>
    );
};

export default InactiveLink;