import Tooltip from './Tooltip'; // Import the Tooltip component if it's in a separate file

const ToolbarButton = ({ text, icon, onClick }) => (
  <Tooltip text={text}>
    <button
      type="button"
      className="p-2 ml-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
      onClick={onClick}
    >
      {icon}
      <span className="sr-only">{text}</span>
    </button>
  </Tooltip>
);

export default ToolbarButton;
