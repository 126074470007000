import React, { useEffect, useState } from 'react';

const commonpClassStyles = "text-sm text-gray-800";

interface MedicalHxProps {
    formData: FormDataItem[] | null;
}

interface FormDataItem {
    uuid: string;
    formContent: string | Record<string, string>;
    formType: string;
}

const MedicalHx: React.FC<MedicalHxProps> = ({ formData }) => {
    const [medicalHx, setMedicalHx] = useState<FormDataItem[]>([]);
    const [fmHx, setFmHx] = useState<FormDataItem[]>([]);
    const [lifestyle, setLifestyle] = useState<FormDataItem[]>([]);

    useEffect(() => {
        if (formData) {
            const filteredMedicalHx = formData.filter(item => item.formType === 'PastMedicalHx');
            const filteredFmHx = formData.filter(item => item.formType === 'FamilyHx');
            const filteredLifestyle = formData.filter(item => item.formType === 'LifestyleHx');

            setMedicalHx(filteredMedicalHx);
            setFmHx(filteredFmHx);
            setLifestyle(filteredLifestyle);
        }
    }, [formData]);

    // Check if formData is empty
    if (!formData || formData.length === 0) {
        return (
            <div className="text-red-600">
                Missing data. Please contact your clinic.
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 pt-2">
                {/* Past Medical History */}
                <div className="w-full sm:w-1/3 bg-purple-50 shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="p-4 md:p-7">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                            Past Medical History
                        </h3>
                        {medicalHx.map((item) => (
                            <div key={item.uuid} className="mb-4">
                                <ul>
                                    {typeof item.formContent === 'string' && item.formContent.split('\n').map((line, index) => (
                                        <li key={index} className={commonpClassStyles} dangerouslySetInnerHTML={{ __html: line.replace(/\n/g, "<br />") }}></li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Family History */}
                <div className="w-full sm:w-1/3 bg-purple-100 shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="p-4 md:p-7">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                            Family History
                        </h3>
                        {fmHx.map((item) => (
                            <div key={item.uuid} className="mb-4">
                                <ul>
                                    {Array.isArray(item.formContent) && item.formContent.map((relationshipItem: { familyMember: string, diseases: string }, index) => (
                                        <li key={index} className={commonpClassStyles}>
                                            {`${relationshipItem.familyMember}: ${relationshipItem.diseases}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Lifestyle */}
                <div className="w-full sm:w-1/3 bg-purple-200 shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                    <div className="p-4 md:p-7">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                            Lifestyle
                        </h3>
                        {lifestyle.map((item) => (
                            <div key={item.uuid} className="pt-2 mb-4">
                                <ul>
                                    {typeof item.formContent === 'string' && item.formContent.split('\n').map((line, index) => (
                                        <li key={index} className={commonpClassStyles} dangerouslySetInnerHTML={{ __html: line.replace(/\n/g, "<br />") }}></li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MedicalHx;