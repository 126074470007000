import { useEffect } from 'react';

function useDisableAutofill() {
  useEffect(() => {
    // Select all input fields with autocomplete attribute
    const inputFields = document.querySelectorAll('input[autocomplete]');
    
    // Loop through input fields and set autocomplete attribute to off
    inputFields.forEach((input) => {
      input.setAttribute('autocomplete', 'off');
    });
  }, []);
}

export default useDisableAutofill;
