// Function to check to see if the field matches the config requirements under json config file
const validateForm = (formData, formValidationConfig, setFormErrorMessages) => {
    let errors = {};

    Object.keys(formData).forEach(fieldName => {
        const value = formData[fieldName];
        const fieldConfig = formValidationConfig.fields.find(field => field.name === fieldName);

        if (!fieldConfig) return; // Field configuration not found

        const { label, minLength, maxLength, regex, errorMessage } = fieldConfig;

        if (minLength && value && value.length < minLength) {
            errors[fieldName] = `${label} must be at least ${minLength} characters long.`;
            return;
        }

        if (maxLength && value && value.length > maxLength) {
            errors[fieldName] = `${label} cannot exceed ${maxLength} characters.`;
            return;
        }

        if (regex && value && !new RegExp(regex).test(value)) {
            errors[fieldName] = errorMessage;
            return;
        }
    });

    // Update the state with the error messages
    setFormErrorMessages(errors);
     // Return the errors object
     return errors;
};

export default validateForm;
