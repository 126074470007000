const calculateAge = (birthdate) => {
    if (!birthdate) return 'Invalid Date'; // Handle missing date
  
    // Attempt to parse the date
    const birthDate = new Date(birthdate);
    // console.log("birthDate",birthDate)
    // Check if the parsing failed
    if (isNaN(birthDate.getTime())) {
      console.error('Date parsing failed for:', birthdate);
      return 'Invalid Date';
    }
  
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birthdate has not occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };
  export default calculateAge;
  