// HSG.tsx
import React from "react";
import TablePatientList from "./TablePatientList";
import Breadcrumb from "../components/Breadcrumb";


const PatientList = () => {
  return (
    <>
      <Breadcrumb pageName="Patient List" />
      <div className="flex flex-col gap-10">
          <TablePatientList />
      </div>
    </>
  );
};

export default PatientList;