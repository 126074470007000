import React, { useState, useEffect } from 'react';
import axios from 'axios';
import step3 from '../../images/cover/step3.svg';
import { Plus } from 'lucide-react';
import {
  CardHeader,
  Typography,
  Button,
} from "@material-tailwind/react"
import Tooltip from '../Tooltip';
import sanitizeInput from '../../function/sanitizeInput'
import validateForm from '../../function/formValidator'
import validateMissingFields from '../../function/missingFieldValidator';
import formValidationConfig from '../../config/formValidationConfig.json';
import ErrorAlert from "../alerts/ErrorAlert";
import SuccessAlert from '../alerts/SuccessAlert';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL

const roleOptions = [
  { value: "Super Admin", label: "Super Admin" },
  { value: "Doctor", label: "Doctor" },
  { value: "Staff", label: "Staff" },
];

const inputSelectorStyle =
  "bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";
const requiredAsteriskStyle = "text-red-500 mx-1";

// Define FormData interface
interface FormData {
  name: string,
  email: string,
  role: string,
}

type FormErrorMessages = Partial<FormData>;

const StepThreeForm = ({ clinicKey, onPrev }) => {
  const [formData, setFormData] = useState([
    { name: '', email: '', role: '' }
  ]);
  const [formErrorMessages, setFormErrorMessages] = useState<FormErrorMessages>({
    name: undefined,
    email: undefined,
    role: undefined,
  });
  const [errorBoxHighlight, setErrorBoxHighlight] = useState({
    name: false,
    email: false,
    role: false,

  });

  const [success, setSuccess] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    localStorage.setItem('Step3UserDetails', JSON.stringify(formData));
  }, [formData]);

  const inputSelectorStyle = (fieldName, readOnly) => `
  ${readOnly ? 'p-0 bg-white border-b-white border-t-white border-r-white border-l-white  focus:ring-white focus:border-white' : 'bg-gray-50'} border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 
  ${errorBoxHighlight[fieldName] ? 'border-red-500' : 'border-gray-300'}
  block w-full`;


  const handleInputChange = (e, index) => {
   // console.log('Index:', index);
    const { name, value } = e.target;
   // console.log('Name:', name);
   // console.log('Value:', value);

    // Update the state based on the input field name and index
    setFormData(prevFormData => {
      // Make a copy of the previous state array
      const updatedFormData = [...prevFormData];
      // Update the specific element in the array with the new value
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      // Return the updated array
      return updatedFormData;
    });


    //There is no required fields in this form so it might not be useful
    // Clear the error message for the current field when it changes
    setFormErrorMessages(prevFormErrorMessages => ({ ...prevFormErrorMessages, [name]: "" }));

    // Clear errorBoxHighlight for the current field
    setErrorBoxHighlight(prevErrorBoxHighlight => ({ ...prevErrorBoxHighlight, [name]: false }));

    // Validate form fields directly without delay
    const updatedFormData = formData.map(data => ({ ...data, [name]: value }));
    validateForm(updatedFormData, formValidationConfig, setFormErrorMessages, Object.keys(updatedFormData));

    // Additional logic to clear validation check if input is in the right format
    const fieldConfig = formValidationConfig.fields.find(field => field.name === name);
    if (fieldConfig && fieldConfig.regex && new RegExp(fieldConfig.regex).test(value)) {
      setFormErrorMessages(prevFormErrorMessages => ({ ...prevFormErrorMessages, [name]: "" }));
    }

    setErrorBoxHighlight(prevErrorBoxHighlight => ({ ...prevErrorBoxHighlight, [name]: !!formErrorMessages[name] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Call validateMissingFields to check for missing fields
    const fieldsToCheck = Object.keys(formData); // Get the field names from the formData object
    const missingFields = validateMissingFields(formData, formValidationConfig, fieldsToCheck);
  
    // Check if there are any missing fields
    if (missingFields.length > 0) {
      // Display alert for missing fields
      const errorObject = {};
      missingFields.forEach(field => {
        errorObject[field.name] = `${field.label} is required.`;
      });
      setFormErrorMessages(errorObject);
  
      // Highlight the input fields with missing data
      const updatedErrorBoxHighlight = { ...errorBoxHighlight }; // Create a copy of the current state
      fieldsToCheck.forEach(field => {
        updatedErrorBoxHighlight[field] = missingFields.some(missingField => missingField.name === field);
      });
      setErrorBoxHighlight(updatedErrorBoxHighlight);
  
      setAlertMessage('Missing fields. Please fill out required fields before submission.')
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return;
    }
  
    const userData = formData.map((user) => ({
      name: user.name, // Use user.name instead of formData.name
      email: user.email, // Use user.email instead of formData.email
    }));
    // console.log("userData", userData);
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/createclinic/users`,
        userData, // Send userData as a single object
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 201 || response.status === 200) {
        if (response.data && response.data.createdUserIds) {
          const createdUserIds = response.data.createdUserIds;
  
          const payload = createdUserIds.map((userId, index) => ({
            userId: userId,
            clinicId: clinicKey,
            role: formData[index].role, // Assign role from the corresponding user
          }));
          // console.log('payload', payload);
  
          try {
            // Send a single payload containing multiple objects
            const associationResponse = await axios.post(
              `${REACT_APP_API_BASE_URL}/createclinic/associate`,
              payload,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
  
            if (associationResponse.status === 201 || associationResponse.status === 200) {
              // Clear Step2ClinicDetails and Step3UserDetails from Local Storage
              localStorage.removeItem('Step2ClinicDetails');
              localStorage.removeItem('Step3UserDetails');
              localStorage.removeItem('clinicId');
              localStorage.removeItem('invitationCode');
              setSuccess(true);
              window.location.href = `${REACT_APP_FRONTEND_BASE_URL}/auth/signup`;
            } else {
              console.error('Error occurred in association creation:', associationResponse.statusText);
              // Handle errors for association creation
            }
          } catch (error) {
            if (error.response && error.response.status === 422) {
              // Extract error messages from validation errors
              const validationErrors = error.response.data.errors;
              const errorMessages = validationErrors.map(error => error.msg);
              // console.log("errorMessages",errorMessages);
              // Display error messages to the user
              alert('Validation error: ' + errorMessages.join(', '));
            } else {
              console.error('Error submitting data:', error);
              // Display a generic error message to the user
              alert('An error occurred while submitting data. Please try again later.');
            }
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Extract error messages from validation errors
        const validationErrors = error.response.data.errors;
        const errorMessages = validationErrors.map(error => error.msg);
        // console.log("errorMessages",errorMessages);
        // Display error messages to the user
        alert('Validation error: ' + errorMessages.join(', '));
      } else {
        console.error('Error submitting data:', error);
        // Display a generic error message to the user
        alert('An error occurred while submitting data. Please try again later.');
      }
      console.error('Error occurred in the main request:', error);
      // Handle errors here for the main POST request
    }
  };
  

  const handleBack = (e) => {
    e.preventDefault();
    // Storing data in local storage after successful submission
    localStorage.setItem('Step3UserDetails', JSON.stringify(formData));
    onPrev(); // Move back to the previous step
  };

  const handleAddUserRow = (e) => {
    e.preventDefault();
    setFormData([...formData, { name: '', email: '', role: '' }]);
  };

  return (
    <>
      <div className="space-y-4 md:space-y-6 sm:p-8 text-center">
        <ErrorAlert
          text={alertMessage}
          show={showErrorAlert} />
        <SuccessAlert
          text="Form saved."
          show={success} />
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src={step3}
            alt="card-image"
          />
        </CardHeader>
        <a href="https://www.milkiway.ai/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
        </a>
        <Typography variant="small" color="black" className="text-left">
          Step 3 of 3
        </Typography>
        <div>
          <p className="text-left font-bold text-title-lg text-black">
            Add users
          </p>
        </div>
        <div className="flex flex-grow justify-center">
          <div className="flex text-start">
            <p className="pb-2 text-base text-gray-900 dark:text-white">Please add members to your clinic and indicate their roles to complete your clinic setup.</p>
          </div>
          <div className="pl-8 flex">
            <Tooltip text="Add more users">
              <Button
                onClick={handleAddUserRow}
              > <Plus className="inline-block w-4 h-8" />
              </Button>
            </Tooltip>
          </div>
        </div>


        <div className="flex flex-col space-y-6">
          {formData.map((user, index) => (
            <div key={index} className="flex flex-col space-y-4 md:space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-grow">
                  <input
                    type="text"
                    name="name"
                    value={sanitizeInput(user.name)}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Name"
                    className={inputSelectorStyle("name", false)}
                  />
                  {errorBoxHighlight.name && (
                    <p className="text-sm text-red-500"> {formErrorMessages.name} </p>
                  )}
                </div>
                <div className="flex-grow">
                  <input
                    type="email"
                    name="email"
                    value={sanitizeInput(user.email)}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Email"
                    className={inputSelectorStyle("email", false)}
                  />
                  {errorBoxHighlight.email && (
                    <p className="text-sm text-red-500"> {formErrorMessages.email} </p>
                  )}
                </div>
                <div className="flex-grow">
                  <select
                    name="role"
                    value={sanitizeInput(user.role)}
                    onChange={(e) => handleInputChange(e, index)}
                    className={inputSelectorStyle("role", false)}
                  >
                    <option value="">Select Role</option>
                    {roleOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errorBoxHighlight.role && (
                    <p className="text-sm text-red-500"> {formErrorMessages.role} </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row">
          <button
            onClick={handleBack}
            className="w-1/2 flex-grow text-gray-600 border boder-text-gray-600 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 mr-2"
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            type="submit"
            className="w-1/2 flex-grow text-white bg-gradient-to-r from-green-500 to-teal-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 ml-2"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default StepThreeForm;
