import React, { useState, useEffect } from 'react';
import step1 from '../../images/cover/step1.svg';
import {
  CardHeader,
  Typography,
} from "@material-tailwind/react"
import sanitizeInput from '../../function/sanitizeInput'

const inputSelectorStyle =
  "bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500";
const requiredAsteriskStyle = "text-red-500 mx-1";

const StepOneForm = ({ onNext }) => {
  const [clinicId, setClinicId] = useState('');
  const [invitationCode, setInvitationCode] = useState('');

  // Load clinicId from localStorage when the component mounts
  useEffect(() => {
    const savedClinicId = localStorage.getItem('clinicId');
    const SavedInvitationCode = localStorage.getItem('invitationCode');

    if (savedClinicId) {
      setClinicId(savedClinicId);
      setInvitationCode(SavedInvitationCode);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('clinicId', clinicId);
    localStorage.setItem('invitationCode', invitationCode);
    onNext(clinicId, invitationCode); // Pass clinicId and invitationCode to the parent component
  };

const handleInputChange = (e) => {
  const { id, value } = e.target;

  if (id === 'clinicId') {
    setClinicId(value);
  } else if (id === 'invitationCode') {
    setInvitationCode(value);
  }
};


  return (
    <>
      <div className="space-y-4 md:space-y-6 sm:p-8 text-center">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src={step1}
            alt="card-image"
          />
        </CardHeader>
        <Typography variant="small" color="black" className="text-left">
          Step 1 of 3
        </Typography>
        <div>
          <p className="text-left font-bold text-title-lg text-black pb-2">
            Please set a clinic ID
          </p>
          <p className="pb-2 text-base text-gray-900 dark:text-white text-left">
            Choose something that your team will recognize.</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="clinicId" className="text-start block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Clinic Id  <span className={requiredAsteriskStyle}>*</span>
            </label>
            <input
              type="text"
              id="clinicId"
              value={sanitizeInput(clinicId)}
              onChange={handleInputChange}
              placeholder='Ex: ABCMedicalGroup'
              className={inputSelectorStyle}
              required
            />
             <label htmlFor="invitationCode" className="mt-4 text-start block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Invitation Code <span className={requiredAsteriskStyle}>*</span>
            </label>
            <input
              type="text"
              id="invitationCode"
              value={sanitizeInput(invitationCode)}
              onChange={handleInputChange}
              className={inputSelectorStyle}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full text-white bg-gradient-to-r from-blue-500 to-purple-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-base px-5 py-4 text-center mb-2"
          >
            Next
          </button>
        </form>
      </div>
    </>
  );
};

export default StepOneForm;
