import React, { useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import logo from '../../images/logo/logo-colorful.png';
import backgroundImage from '../../images/hero/galaxy.jpg';
import Footer from '../../components/Footer';
import { v4 as uuidv4 } from 'uuid';
import useDisableAutofill from '../../hooks/useDisableAutofill';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    userUuid: ''
  });
  useDisableAutofill();

  const uuid = uuidv4();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isInvalid, setIsInvalid] = useState(false); // Initialize isInvalid state as false

  const handleChange = (e) => {
    // Update form data as the user types
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Prepare the data to send to the server
    const data = {
      name: formData.name,
      email: formData.email,
      userUuid: uuid,
    };

   // console.log("Data sent to API", data);

    try {
      // Make an Axios POST request to send the data to your server
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/signup/generate`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // User created successfully, display the success message
        setSuccessMessage(response.data.message);
        setErrorMessage(''); // Clear any previous error message
      } else if (response.status === 400) {
        // Handle a 400 status code (e.g., validation error)
        setErrorMessage(response.data.message);
        setSuccessMessage(''); // Clear any previous success message
      } else if (response.status === 500) {
        // Handle a 400 status code (e.g., validation error)
        setErrorMessage(response.data.message);
        setSuccessMessage(''); // Clear any previous success message
      } else {
        // Handle other status codes (if needed)
        setErrorMessage('No email found. Please contact your clinic adminstrator to create an account.');
        setSuccessMessage('');
      }
    } catch (error) {
      // Handle errors (e.g., display an error message)
      setErrorMessage('No account found. Contact your clinic adminstrator to create an account.');
      setSuccessMessage('');
      console.error('Error creating user:', error);
    }
  };

  return (
    <section className="flex bg-black dark:bg-gray-900">
      {/* Left Column: Image */}
      <div className="md:flex-1 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})`, minHeight: '100vh' }}></div>
      {/* Right Column: Login Form */}
      <div className="flex-1 flex flex-col items-center justify-center px-6 md:h-auto" style={{ minHeight: '100vh' }}>
        <a href="https://www.milkiway.ai/" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
          <img src={logo} alt="logo" className="h-30 w-auto" />
        </a>
        <div className="w-3/4 bg-white rounded-lg shadow dark:border sm:max-w-md dark:bg-gray-800 dark:border-gray-700">
          <form noValidate onSubmit={handleSubmit}>
            <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Welcome to Milkiway
              </h1>

              <div>
                {successMessage && (
                  <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="font-medium pr-2">Success!</span> {successMessage}
                  </div>
                )}

                {errorMessage && (
                  <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="font-medium pr-2">Error!</span> {errorMessage}
                  </div>
                )}
                <div className="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                  <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  Please enter the email that the clinic used to register you as a user and update your name.
                </div>

                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Full name"
                    onChange={handleChange}
                  />
                </div>
                <label className="block">
                  <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Email
                  </span>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${isInvalid ? 'border-pink-600 focus:ring-pink-600 focus:border-pink-600 text-pink-600' : ''
                      }`}
                    placeholder="name@company.com"
                    onChange={handleChange}
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    required
                  />

                  {isInvalid && (
                    <p className="mt-2 text-pink-600 text-sm">
                      Please enter a valid email address
                    </p>
                  )}

                </label>
              </div>


              <button
                type="submit" // Use type="submit" to trigger the form submission
                className="w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-5 py-2.5 text-center mr-2 mb-2"
              >
                Sign up
              </button>
              {/* Google OAuth */}
             {/*  <a href={`${REACT_APP_API_BASE_URL}/oauth/auth/google`} className="flex w-full items-center justify-center gap-3.5 rounded-lg border border-stroke bg-gray p-4 hover:bg-opacity-50 dark:border-strokedark dark:bg-meta-4 dark:hover-bg-opacity-50">
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_191_13499)">
                      <path
                        d="M19.999 10.2217C20.0111 9.53428 19.9387 8.84788 19.7834 8.17737H10.2031V11.8884H15.8266C15.7201 12.5391 15.4804 13.162 15.1219 13.7195C14.7634 14.2771 14.2935 14.7578 13.7405 15.1328L13.7209 15.2571L16.7502 17.5568L16.96 17.5774C18.8873 15.8329 19.9986 13.2661 19.9986 10.2217"
                        fill="#4285F4"
                      />
                      <path
                        d="M10.2055 19.9999C12.9605 19.9999 15.2734 19.111 16.9629 17.5777L13.7429 15.1331C12.8813 15.7221 11.7248 16.1333 10.2055 16.1333C8.91513 16.1259 7.65991 15.7205 6.61791 14.9745C5.57592 14.2286 4.80007 13.1801 4.40044 11.9777L4.28085 11.9877L1.13101 14.3765L1.08984 14.4887C1.93817 16.1456 3.24007 17.5386 4.84997 18.5118C6.45987 19.4851 8.31429 20.0004 10.2059 19.9999"
                        fill="#34A853"
                      />
                      <path
                        d="M4.39899 11.9777C4.1758 11.3411 4.06063 10.673 4.05807 9.99996C4.06218 9.32799 4.1731 8.66075 4.38684 8.02225L4.38115 7.88968L1.19269 5.4624L1.0884 5.51101C0.372763 6.90343 0 8.4408 0 9.99987C0 11.5589 0.372763 13.0963 1.0884 14.4887L4.39899 11.9777Z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M10.2059 3.86663C11.668 3.84438 13.0822 4.37803 14.1515 5.35558L17.0313 2.59996C15.1843 0.901848 12.7383 -0.0298855 10.2059 -3.6784e-05C8.31431 -0.000477834 6.4599 0.514732 4.85001 1.48798C3.24011 2.46124 1.9382 3.85416 1.08984 5.51101L4.38946 8.02225C4.79303 6.82005 5.57145 5.77231 6.61498 5.02675C7.65851 4.28118 8.9145 3.87541 10.2059 3.86663Z"
                        fill="#EB4335"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_191_13499">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                Sign up with Google
              </a> */}
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account? <a href="/auth/signin" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign in</a>
              </p>

            </div>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default SignUp;
