import React, { useEffect, useState } from 'react';

interface BodyFatProps {
  measureData: {
    bodyfat: number;
    createdAt: string;
    PatientId: number;
  };
  gender: 'Male' | 'Female'; // Expect gender as 'Male' or 'Female'
}

const commonpClassStyles = 'text-sm text-gray-800 h-20';

// Function to determine the body fat category based on gender and body fat percentage
const getBodyFatCategory = (
  gender: 'Male' | 'Female',
  bodyfat: number,
): string => {
  if (gender === 'Male') {
    if (bodyfat < 10) return 'Low';
    if (bodyfat >= 10 && bodyfat <= 20) return 'Normal';
    if (bodyfat > 20 && bodyfat <= 25) return 'Slightly High';
    if (bodyfat > 25) return 'High';
  } else if (gender === 'Female') {
    if (bodyfat < 20) return 'Low';
    if (bodyfat >= 20 && bodyfat <= 30) return 'Normal';
    if (bodyfat > 30 && bodyfat <= 35) return 'Slightly High';
    if (bodyfat > 35) return 'High';
  }
  return 'Unknown';
};

const BodyFat: React.FC<BodyFatProps> = ({ measureData, gender }) => {
  const [bodyfat, setBodyfat] = useState<number | null>(null);
  const [bodyfatCategory, setBodyfatCategory] = useState<string | null>(null);

  useEffect(() => {
    if (measureData) {
      setBodyfat(measureData.bodyfat);
      // Calculate the body fat category based on gender and body fat percentage
      const category = getBodyFatCategory(gender, measureData.bodyfat);
      setBodyfatCategory(category);
    }
  }, [measureData, gender]);

  if (bodyfat === null || bodyfatCategory === null) {
    return null; // Render nothing until data is available
  }

  // Define the content based on body fat value
  let biometricsContent = (
    <p className={commonpClassStyles}>
      Your body fat percentage is <strong>{bodyfat}%</strong>. Based on your
      gender ({gender}), your body fat category is{' '}
      <strong>{bodyfatCategory}</strong>.
    </p>
  );

  // Check if measureData is empty
  if (!measureData || Object.keys(measureData).length === 0) {
    return (
      <div className="text-red-600">
        Missing data. Please contact your clinic.
      </div>
    );
  }

  return (
    <div id="bodyfat" className="flex">
      <div className="w-full">
        {biometricsContent}
        <div className="min-w-full overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="w-96 divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-6 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Body Fat (%)
                  </th>
                  <th
                    scope="col"
                    className="h-14 px-3 py-2 sm:px-6 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Body Fat Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`h-10 px-3 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200
                     ${bodyfatCategory === 'Low' ? 'text-yellow-400' : ''}
                     ${bodyfatCategory === 'Normal' ? 'text-green-700' : ''}
                     ${bodyfatCategory === 'Slightly High' ? 'text-amber-400' : ''}
                     ${bodyfatCategory === 'High' ? 'text-red-700' : ''}
                     `}
                  >
                    {bodyfat}
                  </td>
                  <td className="px-3 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200">
                    {bodyfatCategory === 'Normal' ? (
                      <span className="px-1 py-1 rounded text-xs bg-green-100 text-green-700">
                        {bodyfatCategory}
                      </span>
                    ) : bodyfatCategory === 'Low' ? (
                      <span className="px-1 py-1 rounded text-xs bg-yellow-100 text-yellow-600">
                        {bodyfatCategory}
                      </span>
                    ) : bodyfatCategory === 'Slightly High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-amber-100 text-amber-600">
                        {bodyfatCategory}
                      </span>
                    ) : bodyfatCategory === 'High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-700">
                        {bodyfatCategory}
                      </span>
                    ) : (
                      <span className="px-1 py-1 rounded text-xs bg-gray-100 text-gray-700">
                        Unknown
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2 p-4"></div>
    </div>
  );
};

export default BodyFat;
