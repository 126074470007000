import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';


interface BMIRangeChartProps {
    height: number;
    weight: number;
}


const calculateBMI = (height: number, weight: number) => {
    const heightInMeters = height / 100;
    return weight / (heightInMeters * heightInMeters);
};

const BMIRangeChart: React.FC<BMIRangeChartProps> = ({}) => {
    const generateValues = () => {
        const heightValues = [];
        const weightValues = [];

        // Generate height 
        for (let height = 140; height <= 200; height += 4) {
            heightValues.push(height);
        }

        // Generate weight 
        for (let weight = 40; weight <= 100; weight += 4) {
            weightValues.push(weight);
        }

        return { heightValues, weightValues };
    };

    // Call the function to generate the values
    const { heightValues, weightValues } = generateValues();

    const seriesData = weightValues.map((weight, weightIndex) => ({
        name: weight.toString(),
        data: heightValues.map((height, heightIndex) => ({
            x: height,
            y: parseFloat(calculateBMI(height, weight).toFixed(1)),
        })),
    }));

    // console.log(seriesData); // Check the structure of seriesData in the console


 

    const options: ApexOptions = {
        chart: {
            type: 'heatmap',
            toolbar: {
                show: false,
              },
    },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0.5,
                radius: 0,
                useFillColorAsStroke: true,
                colorScale: {
                    ranges: [
                        {
                            from: 0,
                            to: 16,
                            name: 'Severe Thinness',
                            color: '#fbbf24',
                        },
                        {
                            from: 16.01,
                            to: 18.5,
                            name: 'Thin',
                            color: '#84cc16',
                        },
                        {
                            from: 18.60,
                            to: 23,
                            name: 'Normal',
                            color: '#00A100',
                        },
                        {
                            from: 23.01,
                            to: 29.90,
                            name: 'Overweight',
                            color: '#f87171',
                        },
                        {
                            from: 30,
                            to: 94,
                            name: 'Obese',
                            color: '#dc2626',
                        },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
        },
        xaxis: {
            tooltip: {
                enabled: false
            },
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: false,
            }
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: true,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            custom: undefined,
            fillSeriesColor: false,
            style: {
                fontSize: '12px',
                fontFamily: undefined,
            },
            onDatasetHover: {
                highlightDataSeries: true,
            },
            x: {
                show: true,
                formatter: (height) => `Height: ${height}`,
            },
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    const height = w.config.xaxis.categories[dataPointIndex];
                    const weight = w.globals.series[seriesIndex][dataPointIndex];
                    const bmi = calculateBMI(height, weight).toFixed(2);

                    return `<div className="flex flex-col">` + `BMI: ${value}` + `<\div>`;
                },
                title: {
                    formatter: (series) => `Weight: ${series}`, // Set the y-axis title as "Weight"
                },
            },
            marker: {
                show: false,
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        },
        legend: {
            show: true,
            position: 'bottom',
        },
        responsive: [{
            breakpoint: undefined,
            options: {},
        }],
        /* annotations: {
            points: [
              {
                x: userHeight,
                y: userWeight,
                marker: {
                  size: 6,
                  fillColor: '#ffffff',
                  strokeColor: '#ffffff',
                  radius: 0.5,
                  cssClass: 'apexcharts-custom-class',
                },
                label: {
                  borderColor: '#ffffff',
                  offsetY: -8,
                  style: {
                    color: '#000000',
                    background: '#ffffff',
                  },
                  text: `Height: ${userHeight}cm | Weight: ${userWeight}kg | BMI: ${userBMI}`,
                },
              },
            ],
        }, */
    };

    return (
<div id="chart">
  <div className="flex">
      <div className="w-10/12 pr-4">
      <ReactApexChart
        options={options}
        series={seriesData}
        type="heatmap"
        height={240}
        width={480}
      />
    </div>
    </div>
</div>
    );
};

export default BMIRangeChart;
