import React, { useEffect, useState } from 'react';
import EmpathizeLine from '../../images/icon/icon-empathize-line.svg';

interface ExamFindingsProps {
  formData: FormData[] | null; // Updated the type to match FormDataItem[]
}

interface FormData {
  id: number;
  formType: string;
  formContent: {
    category: string;
    description: string;
    status: string;
  }[];
  uuid: string;
  createdAt: string;
  updatedAt: string;
  PatientId: number;
}

const commonpDarkClassStyles = "mt-1 text-sm sm:text-sm font-medium leading-6 text-black dark:text-gray-200";
const commonpClassStyles = "mt-1 text-sm sm:text-sm leading-6 text-gray-500 dark:text-gray-400";

const ExamFindings: React.FC<ExamFindingsProps> = ({ formData }) => {
  const [physicalExamData, setPhysicalExamData] = useState<FormData[]>([]);

  useEffect(() => {
    if(formData){
        // Filter the data where formType is "PhysicalExam"
        const physicalExamData = formData
          .filter((item: FormData) => item.formType === 'PhysicalExam')
          .map((item) => item.formContent)
          .flat(); // Flatten the array of formContent

        setPhysicalExamData(physicalExamData);
      }
  }, [formData]);

  return (
    <div id="physicalexam">
      <ul className="flex flex-col pt-2 overflow-x-auto">
        {physicalExamData.map((item, index) => (
          <li
            key={index}
            className="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          >
            <img src={EmpathizeLine} alt="Logo" className="inline w-4 h-auto text-xs sm:text-sm " />
            <div className="w-1/6">
              <p className={commonpDarkClassStyles}>{item.category}</p>
            </div>
            <div className="w-4/6">
              <p className={commonpClassStyles}>{item.description}</p>
            </div>
            <div className="w-1/6 text-xs sm:text-sm font-medium">
            <p className={
                item.status === 'Normal' ? 'text-green-500' :
                item.status === 'Abnormal' ? 'text-red-500' :
                'text-gray-500'
              }>
                {item.status}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExamFindings;
