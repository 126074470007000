import React from 'react';

interface VisionResult {
    type: string;  // The type of vision test (e.g., Far, Near, Color)
    rightEye?: string;  // Result for the right eye (optional)
    leftEye?: string;  // Result for the left eye (optional)
    aided?: string;  // Aided or unaided vision (optional)
    result?: string;  // Result for color vision (optional)
}

interface VisionProps {
    visionData: VisionResult[];  // Array of vision results
}

const Vision: React.FC<VisionProps> = ({ visionData }) => {
    return (
        <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Vision Report</h3>
            <div className="space-y-4">
                
                {visionData.map((item, index) => (
                    <div key={index} className="border border-gray-200 p-4 rounded-md">
                        <h4 className="text-lg font-medium text-gray-700">{item.type} Vision</h4>
                        {item.type === 'Color' ? (
                            <p className="text-gray-600">Result: {item.result || "No result available"}</p>
                        ) : (
                            <>
                                <p className="text-gray-600">Right Eye: {item.rightEye || "N/A"}</p>
                                <p className="text-gray-600">Left Eye: {item.leftEye || "N/A"}</p>
                                <p className="text-gray-600">Aided: {item.aided || "N/A"}</p>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Vision;
