import { useState, useEffect } from 'react';

const useUserDetails = () => {
  // State to store user data and error information
  const [userDetails, setUserDetails] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [role, setRole] = useState(null);  // Added state to store role
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const defaultUserPicture = "https://milkiway-public-images.s3.ap-southeast-1.amazonaws.com/default-user-image.png";

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }

        const userData = await response.json();
        const { user, clinic, userId, role } = userData; // Assuming role is part of the response

        // Update the state with the fetched user, clinic, and role data
        setUserDetails({
          name: user.name,
          email: user.email,
          picture: user.picture || defaultUserPicture, // Default picture if not available
          id: userId,
          role: role.role,
        });

        setClinicDetails({
          name: clinic.clinicName,
          id: clinic.clinicId,
        });

        setRole(role); // Set the role from the response

        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []); // Empty dependency array means this will run only once when the component mounts

  return { userDetails, clinicDetails, error, isLoading }; // Include role in the return
};

export default useUserDetails;
