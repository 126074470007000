import { X } from "lucide-react";
import { useState, useEffect } from 'react';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const textStyle = "text-sm font-medium text-gray-900 dark:text-white";
const inputSelectorStyle = "bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

const ModalCommentEdit = ({ isOpen, onClose, comment, onSaveComment }) => {
    // console.log("comment:", comment);
    const [editedComment, setEditedComment] = useState(comment ? comment.commentContent : "");

    useEffect(() => {
      setEditedComment(comment ? comment.commentContent : "");
    }, [comment]);
    
    const handleInputChange = (e) => {
      setEditedComment(e.target.value);
    };
    const handleSaveClick = () => {
        if (editedComment.trim() !== '') {
          // Define commentData
          const commentData = {
            PatientId: comment.PatientId,
            MwUserId: comment.MwUserId,
            commentType: "HealthReport",
            commentContent: editedComment,
            commentUuid: comment.commentUuid,
          };

          // Submit the comment and then trigger the refresh callback
          submitComment(commentData);
        }
      };

      const submitComment = (commentData) => {
        // Make a POST request to submit the comment
        fetch(`${REACT_APP_API_BASE_URL}/comments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include credentials (cookies) in the request
          body: JSON.stringify(commentData),
        })
          .then((response) => {
            if (response.ok) {
              // Call the onSaveComment function to refresh comments
              onSaveComment();
              onClose();
            } else {
              console.error('Failed to save comment');
            }
          })
          .catch((error) => {
            console.error('Error saving comment:', error);
          });
      };

  return (
    <div className={`fixed inset-0 ${isOpen ? '' : 'hidden'}`} style={{ zIndex: 1000 }}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-black bg-opacity-75 absolute inset-0 z-10"></div>
        <div className="w-1/2 bg-white p-8 rounded-lg z-20 dark:bg-gray-800">
          <div className="flex justify-between items-center">
            <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Edit your comment</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-primary-500 cursor-pointer relative bottom-8 left-2"
            >
              <X className="inline-block w-4 h-4" />
            </button>
          </div>

          <div className="grid gap-4 mb-4">
            <textarea
              id="Comment"
              rows={10}
              className={`rounded-lg w-full flex gap-x-3.5 py-4 px-4 text-sm ${textStyle} ${inputSelectorStyle}`}
              value={editedComment}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex items-center space-x-4 pt-8">
            <button
              type="button"
              onClick={handleSaveClick}
              className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover-bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark-focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCommentEdit;
