// FormAdditionalFMHx.tsx
import React, { useEffect, useRef } from 'react';

interface FormAdditionalFMHxProps {
  additionalFmHx: string; // The content of the textarea
  handleTextareaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void; // Function to handle textarea changes
}

const textStyle = 'text-sm text-gray-900 dark:text-white';

const FormAdditionalFMHx: React.FC<FormAdditionalFMHxProps> = ({ additionalFmHx, handleTextareaChange }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // Focus the textarea whenever the 'others' state changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [additionalFmHx]);

  return (
    <div className="mb-4">
      <label htmlFor="additionalFmHx" className={textStyle}>
        Additional Family History
      </label>
      <textarea
        id="others"
        value={additionalFmHx}
        onChange={handleTextareaChange}
        ref={textareaRef}
        className="rounded w-full flex gap-x-3.5 py-4 px-4 text-sm bg-white border-gray-200 text-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
        />
    </div>
  );
};

export default FormAdditionalFMHx;
