import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
const Legend = () => {
  return (
    <div className="ml-12 mt-2 flex items-center justify-between w-[calc(100%-120px)]">
      <div className="flex items-center mb-1">
        <div className="w-2.5 h-2.5 bg-green-300 border border-gray-300"></div>
        <span className="ml-1 mr-2 text-xs">Male</span>
      </div>
      <div className="flex items-center mb-1">
        <div className="w-2.5 h-2.5 bg-blue-500 border border-gray-300"></div>
        <span className="ml-1 mr-2 text-xs">Female</span>
      </div>

      <div className="flex items-center mb-1">
        <svg
          className="w-2.5 h-2.5 text-black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M12 .587l3.668 7.568 8.332 1.208-6.042 5.852 1.427 8.332L12 18.896l-7.385 3.878 1.427-8.332-6.042-5.852 8.332-1.208z" />
        </svg>{' '}
        <span className="ml-1 text-xs">Your risk</span>
      </div>
    </div>
  );
};

const FraminghamUSGraph = ({ race, gender, framinghamForms }) => {
  // console.log('framinghamForms received from parent', framinghamForms);
  const [combinedData, setCombinedData] = useState([]);

  // Define the areaData
  const areaData = [
    ['Total Points', 'Male', 'Female'],
    [-3,  1,  1],  
    [-2,  2,  1],  
    [-1,  2,  2],  
    [0,  3,  2],  
    [1,  4,  2],  
    [2,  5,  3],  
    [3,  6,  3],   
    [4,  7,  4],
    [5,  9,  5],
    [6, 11,  6], 
    [7, 14, 7],  
    [8, 18, 8],  
    [9, 22, 9],  
    [10, 27, 11],  
    [11, 33, 13],  
    [12, 40, 15],  
    [13, 47, 17],    
    [14, 56, 20],
    [15, 56, 24],
    [16, 56, 27],
    [17, 56, 32],
    [18, 56, 32],
    [19, 56, 32],
    [20, 56, 32],
];
  // Map of race names to column indices
  const genderIndex = {
    Male: 1,
    Female: 2,
  };

  // Function to calculate risk based on race and total points
  const calculateRisk = (gender, totalPoints) => {
    // Convert totalPoints to a number
    const numericTotalPoints = Number(totalPoints);

    // Get the risk for the selected totalPoints
    const riskRowIndex = areaData.findIndex(
      (row) => row[0] === numericTotalPoints,
    ); // Get the row for the specific totalPoints

    // Retrieve the risk based on race
    let risk = null; // Default value if not found
    if (riskRowIndex !== -1) {
      // Ensure the row was found
      const columnIndex = genderIndex[gender]; // Get the index for the given race
      risk = areaData[riskRowIndex][columnIndex]; // Get the risk value
    }

/*     console.log(
      'Risk for gender',
      gender,
      'at total points',
      numericTotalPoints,
      'is:',
      risk,
    ); // Should log 3 for Chinese at 10 points */
    return risk;
  };

  // Effect to prepare the combined data
  useEffect(() => {
    // Ensure framinghamForms is not empty and has valid data
    if (framinghamForms.length > 0) {
      // Access the first Framingham form's riskData
      const totalPoints = framinghamForms[0].formContent.riskData.totalPoints; // Assuming you're using the first form for this calculation
      // console.log('Total points:', totalPoints);

      // Access the race from the form content
      const risk = calculateRisk(gender, totalPoints); // Get the risk for the provided race and total points

      // Create a DataTable for combined data
      const newCombinedData = [
        ['Total Points', 'Male', 'Female', 'Patient Risk'],
      ];

      // Add area data to combined data
      for (let i = 1; i < areaData.length; i++) {
        // Start from 1 to skip header row
        newCombinedData.push([
          areaData[i][0],
          areaData[i][1],
          areaData[i][2],
          null, // Placeholder for patient risk
        ]);
      }

      // Add the patient risk to the combined data
      newCombinedData.push([totalPoints, null, null, risk]); // Add totalPoints and the retrieved risk

      setCombinedData(newCombinedData); // Update the state with combined data
    } else {
      console.warn('No Framingham forms available.');
    }
  }, [gender, framinghamForms]); // Dependency array

  // Chart options
  const areaOptions = {
    title: '10-Year Cardiovascular Risk',
    hAxis: {
      title: 'Total Points',
      minValue: -3,
      maxValue: 17,
      ticks: [-5, 0, 5, 10, 15, ], // Specify ticks
      gridlines: { count: 1 }, // Optional: customize the number of gridlines
      logScale: false, // Ensure logScale is false
    },
    vAxis: {
      title: 'Risk (%)',
      minValue: 0,
      maxValue: 60,
    },
    series: {
        0: { label: 'Male', color: '#40f040', type: 'area' },
        1: { label: 'Female', color: '#40b0f0', type: 'area' },
        2: { label: 'Patient Risk', color: 'black', type:'scatter', pointShape: 'star', sides: 5, dent: 0.5  } 
    },
    legend: 'none',
  };

  return (
    <div>
      {framinghamForms.length > 0 && (
        <div>
          {/* Display risk score */}
          <div className="mb-4">
            Based on your demographic profile ({race} {gender}), you have a{' '}
            <b>{framinghamForms[0].formContent.riskData.risk} </b>risk of
            experiencing a cardiovascular event within the next 10 years.
          </div>
                {/* Risk Calculation */}

          <div className="mb-4">
            Age ({framinghamForms[0].formContent.riskData.agePoints} point) + blood
            pressure control ({framinghamForms[0].formContent.riskData.bpPoints} point) + HDL (
            {framinghamForms[0].formContent.riskData.hdlPoints} point) + LDL (
                {framinghamForms[0].formContent.riskData.ldlPoints} point) + diabetes status (
                {framinghamForms[0].formContent.riskData.dmPoints} point) + smoking status ({framinghamForms[0].formContent.riskData.smokerPoints} point) ={' '}
            {framinghamForms[0].formContent.riskData.totalPoints} point(s)
          </div>
          <table className="min-w-full text-xs border-collapse border border-gray-300 mb-4">
        <thead>

        </thead>
        <tbody>
          {/* Total Points Row */}
          <tr className="bg-gray-100">
            <td className="border border-gray-300 p-2">Total Points</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[0]}
              </td>
            ))}
          </tr>

          {/* Male Row */}
          {gender=='Male' && (
          <tr className="bg-white">
            <td className="border border-gray-300 p-2">Risk %</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[1]}
              </td>
            ))}
          </tr>
          )}
          {/* Female Row */}
          {gender=='Female' && (
          <tr className="bg-gray-100">
            <td className="border border-gray-300 p-2">Risk %</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[2]}
              </td>
            ))}
          </tr>)}

        </tbody>
      </table>
        </div>
      )}

      {/* Chart rendering */}
      {combinedData.length > 0 && (
        <Chart
          chartType="ComboChart"
          data={combinedData}
          options={areaOptions}
          width="100%"
          height="500px"
          legendToggle
        />
      )}

      <Legend />
      <div className="text-xs py-4">
      <p>
        Adapted from Wilson PW, D’Agostino RB, Levy D, Belanger AM, Silbershatz H, Kannel WB. Prediction of coronary heart disease using risk factor categories. Circulation. 1998;97(18):1843.
    </p>
    <div className="pt-4">    <strong>Reference: </strong>
    <a
        href="https://pubmed.ncbi.nlm.nih.gov/9603539/">
        Prediction of coronary heart disease using risk factor categories
    </a>
    </div>
      </div>
    </div>
  );
};

export default FraminghamUSGraph;
