import React, { useEffect, useState } from 'react';
import Empathize from '../../images/icon/icon-empathize-line.svg';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

const commonpDarkClassStyles = "py-3 pl-6 pr-3 text-sm font-medium text-black text-left";
const commonpClassStyles = "py-3 pr-6 text-sm text-gray-800";

interface RecommendationProps {
    formData: FormDataItem[] | null;
}

interface RecommendationItem {
    recName: string;
    recTitle: string;
    recContent: string;
    nextstepAction?: string;
    nextstepSpecialty?: string;
    nextstepInterval?: string;
    nextstepReasons?: string;
}

interface FormDataItem {
    id: number;
    formType: string;
    formContent: RecommendationItem[]; // Adjusted to match the nested structure
    visitUuid: string;
    vettedBy: string | null;
    createdAt: string;
    updatedAt: string;
    PatientId: number;
}

const Recommendation: React.FC<RecommendationProps> = ({ formData }) => {
    const [recommendations, setRecommendations] = useState<RecommendationItem[]>([]);

    useEffect(() => {
        if (formData) {
            const filteredRecommendations = formData
                .filter(item => item.formType === 'Recommendation')
                .flatMap(item => item.formContent); // Extract and flatten the formContent arrays
            setRecommendations(filteredRecommendations);
            // console.log("Recommendation Set", filteredRecommendations);
        }
    }, [formData]);

    const components = {
        p: ({ children }) => <p className={commonpClassStyles}>{children}</p>,
        a: ({ href, children }) => (
            <a href={href} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        ),
        br: ({ node }) => <br />,
        // Customize the behavior for handling line breaks
        text: ({ value }) => {
            // Replace single '\n' with '  \n ' while preserving '\n\n'
            const modifiedValue = value.replace(/(?<!\n)\n(?!\n)/g, '  \n ');
            // Split text by '\n\n' to create paragraphs
            const paragraphs = modifiedValue.split('\n\n');
            return paragraphs.map((paragraph, index) => (
                <p className={commonpClassStyles} key={index}>
                    {/* Split each paragraph by '\n' to handle line breaks */}
                    {paragraph.split('\n').map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                            {line}
                            {lineIndex < paragraph.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </p>
            ));
        }
    };

    return (
        <div className="pt-2 shadow-sm">
            <table className="my-2 table-auto w-full">
                <thead>
                    <tr>
                        <th className="w-3/12 text-left"></th>
                        <th className="w-9/12 text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    {recommendations.length > 0 ? (
                        recommendations.map((recommendation, index) => (
                            <tr
                                key={index}
                                className={index % 2 === 0 ? 'bg-whiter-100' : 'bg-blue-50'}
                            >
                                <td className={commonpDarkClassStyles}>
                                    <img src={Empathize} alt="Logo" className="inline-block w-6 pr-2" />
                                    {recommendation.recTitle}
                                </td>
                                <td className={commonpClassStyles}>
                                    <ReactMarkdown 
                                      remarkPlugins={[remarkGfm, remarkBreaks]} // Add remarkBreaks here
                                    components={components}>
                                        {typeof recommendation.recContent === 'string' ? recommendation.recContent : recommendation.recContent.recContent}
                                    </ReactMarkdown>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2} className="text-center">No recommendations available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Recommendation;