import React from 'react';
import ChartAudiogram from '../form/tests/ChartAudiogram';

interface AudiogramResult {
    leftEar: {
        [frequency: string]: number | string; // Frequency as keys and hearing levels as values
    };
    rightEar: {
        [frequency: string]: number | string; // Frequency as keys and hearing levels as values
    };
}

interface AudiogramProps {
    audiogramData: AudiogramResult;
}

const Audiogram: React.FC<AudiogramProps> = ({ audiogramData }) => {
    const { leftEar, rightEar } = audiogramData;

    // Extract frequencies from the keys of either ear (assuming they are the same)
    const frequencies = Object.keys(leftEar).filter(frequency => frequency !== "interpretation");

    return (
        <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Audiogram Report</h3>
            
            {/* Correctly accessing the interpretation for each ear */}
            <div className="mb- text-black pb-6">
                <p><strong>Right Ear Interpretation:</strong> {rightEar["interpretation"]}</p>
                <p><strong>Left Ear Interpretation:</strong> {leftEar["interpretation"]}</p>
            </div>
            
            {/* Pivot Table */}
            <table className="min-w-full mt-2 border-collapse border border-gray-300 text-sm text-black">
                <thead>
                    <tr>
                        <th className="border border-gray-300 p-2 text-right">Frequency (Hz)</th>
                        {frequencies.map(frequency => (
                            <th key={frequency} className="border border-gray-300 p-2 w-1/12 sm:w-12">{frequency}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray-300 p-2 font-bold text-right">Right Ear Hearing Level (dB)</td>
                        {frequencies.map(frequency => (
                            <td key={frequency} className="border border-gray-300 p-2">{rightEar[frequency]}</td>
                        ))}
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-2 font-bold text-right">Left Ear Hearing Level (dB)</td>
                        {frequencies.map(frequency => (
                            <td key={frequency} className="border border-gray-300 p-2">{leftEar[frequency]}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
            
            {/* Include the ChartAudiogram component */}
            <ChartAudiogram leftEarData={leftEar} rightEarData={rightEar} />
        </div>
    );
};

export default Audiogram;
