const validateMissingFields = (formData, formValidationConfig, fieldsToCheck) => {
    const missingFields = formValidationConfig.fields.filter(field => {
        // Check if the field is included in the fieldsToCheck array
        if (!fieldsToCheck.includes(field.name)) {
            return false; // Field is not in fieldsToCheck, skip it
        }
        
        // Check if the field has dependencies
        if (field.dependencies && field.dependencies.length > 0) {
            // Check if all dependencies are met
            const dependenciesMet = field.dependencies.every(dependency => {
                return formData[dependency.field] === dependency.value;
            });
            // If dependencies are not met, this field is not required
            if (!dependenciesMet) {
                return false;
            }
        }
        // Check if the field is required and empty
        if (field.required && !formData[field.name]) {
            return true; // Field is required but missing
        }
        return false; // Field is not required or has a value
    });

    return missingFields;
};

export default validateMissingFields;
