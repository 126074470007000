import { lazy } from 'react';
import 'pdfjs-dist/build/pdf.worker.js';

const Outreach = lazy(() => import('../pages/Outreach/Outreach'));
const OutreachDetails = lazy(() => import('../pages/Outreach/OutreachDetails'));

const Calendar = lazy(() => import('../pages/Calendar'));
const BodyMeasurements = lazy(() => import('../pages/Form/BodyMeasurements'));
const Vision = lazy(() => import('../pages/Form/Vision'));
const Hearing = lazy(() => import('../pages/Form/Hearing'));
const Colonoscopy = lazy(() => import('../pages/Form/Colonoscopy'));

const Questionnaires = lazy(() => import('../pages/Questionnaires'));

const Reports = lazy(() => import('../pages/Reports'));
const Profile = lazy(() => import('../pages/Settings/Profile'));
const Settings = lazy(() => import('../pages/Settings/Settings'));
const Billing = lazy(() => import('../pages/Settings/Billing'));
const Templates = lazy(() => import('../pages/Settings/Templates'));

const PatientList = lazy(() => import('../pages/PatientList'));
const Patient = lazy(() => import('../pages/Patient/Patient'));
const PatientScreening  = lazy(() => import('../pages/Patient/PatientScreening'));
const PatientLabs  = lazy(() => import('../pages/Patient/PatientLabs'));
const PatientTestsProcedures  = lazy(() => import('../pages/Patient/PatientTestsProcedures'));
const PatientReport  = lazy(() => import('../pages/Patient/PatientReport'));
const PatientDetails = lazy(() => import('../pages/Patient/PatientDetails'));

const coreRoutes = [
  {
    path: '/clinic/:clinicId',
    title: 'Patient List',
    component: PatientList,
  },
  {
    path: '/outreach',
    title: 'Outreach',
    component: Outreach,
  },
  {
    path: '/outreach/:patientId',
    title: 'Outreach Details',
    component: OutreachDetails,
  },
  {
    path: '/calendar',
    title: 'Calender',
    component: Calendar,
  },
  {
    path: '/forms/body-measures',
    title: 'Body Measurements',
    component: BodyMeasurements,
  },
  {
    path: '/forms/vision',
    title: 'Vision',
    component: Vision,
  },
  {
    path: '/forms/hearing',
    title: 'Hearing',
    component: Hearing,
  },
  {
    path: '/forms/colonoscopy',
    title: 'Colonoscopy',
    component: Colonoscopy,
  },
  {
    path: '/questionnaires',
    title: 'Questionnaires',
    component: Questionnaires,
  },
  {
    path: '/reports',
    title: 'Reports',
    component: Reports,
  },
  {
    path: '/settings',
    title: 'Settings',
    component: Settings,
  },
  {
    path: '/billing',
    title: 'Billing',
    component: Billing,
  },
  {
    path: '/templates',
    title: 'Templates',
    component: Templates,
  },
  {
    path: '/profile',
    title: 'Profile',
    component: Profile,
  },
  {
    path: '/patient/:uuid',
    title: 'Patient',
    component: Patient,
  },
  {
    path: '/patient/screening/:uuid',
    title: 'Screening',
    component: PatientScreening,
  },
  {
    path: '/patient/labs/:uuid',
    title: 'Labs',
    component: PatientLabs,
  },
  {
    path: '/patient/testsprocedures/:uuid',
    title: 'Tests/Procedures',
    component: PatientTestsProcedures,
  },
  {
    path: '/patient/report/:uuid',
    title: 'Report',
    component: PatientReport,
  },
  {
    path: '/patient/details/:uuid',
    title: 'Details',
    component: PatientDetails,
  }
  ];

const routes = [...coreRoutes];
export default routes;
